.container {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: Lato, sans-serif;
}

.messageList {
    flex: 1;
    overflow-y: auto;
}


.message {
    padding: 8px;
    padding-bottom: 0px;
    margin: 5px;
    font-size: 0.8rem;
    border-radius: 5px;
}

.bot {
    background-color: #ccc;
    color: black;
    align-self: flex-start;
}

.user {
    background-color: #2A9D8F;
    color: #1E7767;
    align-self: flex-end;
}

.inputContainer {
    display: flex;
    margin-top: 10px;
}


.chatInput {
    flex: 1;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
}


.sendButton {
    padding: 8px 15px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.sendButton:hover {
    background-color: #1E7767;
}
