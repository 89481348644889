.container {
    width: 100%;
    background-color: #e6f7ff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.listItem {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

.listItem:nth-child(odd) {
    background-color: #f2f2f2;
}

.noAchievements {
    text-align: center;
    padding: 8px;
    margin-bottom: 10px;
}

.title {
    font-weight: 600;
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.actionButton {
    padding: 5px 10px;
    margin: 5px 0 5px 5px; 
    cursor: pointer;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
    white-space: nowrap; 
}

.actionButton:hover {
    background-color: #1E7767;
}

.actionButton:active {
    transform: scale(0.95);
}

.styledInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    margin-right: 10px; 

    
}

.addAchievementArea {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-top: 20px;
    width: 100%; 
}

.addAchievementArea .styledInput {
    flex-grow: 1;
}

.achievementTitle {
    flex: 5;
    font-size: 0.8em; 
    margin: 2px 0;
    font-weight: 800;
}

.achievementDate {
    flex: 1;
    font-size: 0.8em; 
    margin: 2px 0; 
}

@media (max-width: 768px) {
    .listItem {
        justify-content: flex-start; 
    }

    .achievementTitle,
    .achievementDate {
        flex-basis: 100%; 
        margin: 5px 0; 
    }

    .actionButton {
        padding: 5px; 
    }

    .addAchievementArea {
        flex-direction: column; 
    }

    .addAchievementArea .styledInput,
    .addAchievementArea .actionButton {
        width: 100%; 
        margin-right: 0; 
        margin-bottom: 10px; 
    }
}
