.container {
    width: 100%;
    background-color: #fff0e6;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.title {
    font-weight: 600;
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.listItem {

    padding: 8px;
}

.listItem:nth-child(odd) {
    background-color: #f2f2f2;
}

.advice {
    font-size: 0.8em;
}

.noTips {
    text-align: center;
    padding: 8px;
    margin-bottom: 10px;
}
