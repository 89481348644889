.styledButton {
    text-align: left;
    padding: 12px 16px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: inherit;
    color: inherit;
    width: 100%;
    background-color: #f1f1f1;
    margin-bottom: 3px;
    border-radius: 6px;
    font-family: Lato, sans-serif;
  }
  
  .styledButton:hover {
    background-color: #C5B3B3;
  }
