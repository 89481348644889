.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f7f7f7;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.title strong {
    font-weight: 800;
}

.pricingCard {
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: rgba(205, 127, 50, 0.5);
    padding: 10px;
    margin: 0 20px;
    width: 350px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    font-size: 0.9em;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
}

.pricingCard:hover {
    transform: scale(1.05);
}

.silverMonthlyPricingCard {
    background-color: rgba(192, 192, 192, 0.5);
}

.goldMonthlyPricingCard {
    background-color: rgba(255, 215, 0, 0.5);
}

.tierTitle {
    text-align: center;
    margin-bottom: 20px;
}

.price {
    font-size: 24px;
    text-align: center;
}

.priceStrike {
    text-decoration: line-through;
}

.featureList {
    list-style-type: none;
    padding: 0;
}

.feature {
    margin-bottom: 10px;
    text-align: left;
    font-size: 0.9em;
}

.subscribeButton {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.subscribeButton:hover {
    background-color: darkblue;
}

.subscribeButton:disabled {
    background-color: gray;
    cursor: not-allowed;
}

.note {
    text-align: center;
    font-style: italic;
}

.introParagraph {
    text-align: center;
    font-size: 18px;
    max-width: 800px;
    margin: 0 auto 40px;
    line-height: 1.6;
    color: #333;
}

.pricingCardContainer {
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
    }
}

.concludingParagraph {
    text-align: center;
    font-size: 18px;
    max-width: 800px;
    margin: 40px auto 0;
    line-height: 1.6;
    color: #333;

    @media (max-width: 768px) {
        margin: 0px auto 0;
    }
}
