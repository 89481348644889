.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.userItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.userTitle {
    flex: 3;
    font-size: 14px;
}

.userAttrib {
    flex: 1;
    font-size: 14px;
    color: #777;
}

.actionButton {
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
}

.actionButton:hover {
    background-color: #1E7767;
}

.actionButton:active {
    transform: scale(0.95);
}

.userHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.userHeaderTitle {
    flex: 3;
    font-size: 14px;
    font-weight: 800;
}

.userHeaderAttrib {
    flex: 1;
    font-size: 14px;
    font-weight: 800;
}
