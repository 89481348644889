.blogListContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 40px 10px;
    font-family: 'Lato', sans-serif;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}

.title {
    font-weight: 600;
    font-size: 24px;
    color: #2A9D8F;
    text-align: center;
}

.title strong {
    font-weight: 800;
}

.blogItemLink {
    text-decoration: none;
    color: inherit;
    width: 100%; /* Makes the entire item clickable */
    max-width: 800px;
    margin: 0 auto;
}

.blogItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #f5f5f5;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds scaling effect */
    cursor: pointer; /* Indicates the entire box is clickable */
    width: 80%;
}

.blogItem:hover {
    transform: scale(1.02); /* Scales the item slightly on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhances the shadow on hover */
}

.blogTitle {
    margin-bottom: 15px;
    text-align: center;
}

.blogSnippet {
    text-align: justify;
    margin-bottom: 20px;
}

.readMoreLink {
    color: #3498db;
    cursor: pointer;
    text-decoration: none;
    align-self: center;
    font-weight: 600;
}

.readMoreLink:hover {
    text-decoration: underline;
}

.blogTopic {
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 3px;
    font-size: 0.9rem;
    margin-bottom: 15px;
}

.imageContainer {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.blogImage {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

@media only screen and (max-width: 800px) {
    .blogListContainer {
        padding: 20px;
    }

    .title {
        font-size: 20px;
    }

    .blogItem {
        width: 90%;
    }
}

.section {
    margin-bottom: 50px;
}
