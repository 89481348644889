.commentsList {
    margin-top: 20px;
    border-top: 1px solid #eee;
    padding-top: 20px;
  }
  
  .commentItem {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .commentAuthor {
    font-size: 0.8em;
    font-weight: bold;
    margin-right: 5px;
  }
  
  .commentText {
    font-size: 0.8em;
    text-align: left;
    white-space: pre-line; /* This ensures that line breaks are honored */
  }

  .noComments {
    font-size: 0.8em;
  }
