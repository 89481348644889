.container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    text-align: center;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.title {
    font-size: 2rem;
    color: #00796B;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 20px;
}

.text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.button {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #1E7767;
}
