.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
}

.title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.paragraph {
    font-size: 1em;
    margin-bottom: 20px;
}

.formContainer {
    display: flex;
    flex-direction: column;
}

.input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.button, .secondaryButton {
    padding: 10px 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.button {
    background-color: #007bff;
    color: white;
}

.secondaryButton {
    background-color: #6c757d;
    color: white;
}

.errorMessage {
    color: red;
    font-size: 0.8em;
}
