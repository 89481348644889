.loginContainer {
    align-items: center;
    padding: 10px;
    font-family: Lato, sans-serif;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.inputField {
    padding: 5px 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    width: 100%;
    max-width: 200px;

    @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 0px;
    }
}

.inputField:focus {
    border-color: blue;
}

.loginButton {
    font-family: Lato, sans-serif;
    padding: 5px 18px;
    background-color: #029A82;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    

    @media (max-width: 768px) {
        margin-bottom: 100px;
    }
    
}

.loginButton:hover {
    background-color: #CE6F72;
}

.logoutContainer {
    display: inline-block;
    margin-left: 10px;
}

.loginLink {
    display: none;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 5px;
    color: white;
    background: transparent;
    border: none;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
}

.loginLink:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
    .showFields {
        display: block;
    }

    .hideFields {
        display: none;
    }
}
