.container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.questionContainer {
    margin-top: 20px;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
}

.question {
    margin: 0;
}

.answer {
    display: none;
    margin-top: 10px;
}

.answerOpen {
    display: block;
}

.link {
    text-decoration: none;
    color: #2A9D8F;
    font-weight: 600;
}

.link:hover {
    text-decoration: underline;
}

.feedback {
    width: 25%;
    margin: 0 auto;
}
