.container {
    position: relative;
    width: 300px;
    display: inline-block;
    font-family: Lato, sans-serif;
}

.chatBotWindow {
    width: 100%;
    height: 400px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #e6f7ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    position: absolute;
    bottom: 40px;
    display: none;
}

.open {
    display: block;
}

.chatbotLink {
    font-family: Lato, sans-serif;
    padding: 5px 5px;
    font-size: 16px;
    background-color: #E9F7EF;
    color: #333333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}
