body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 32px;
    
  @media (min-width: 768px) {
    font-size: 28px;
  }
}

h2 {
    font-size: 28px;

    @media (min-width: 768px) {
      font-size: 18px;
    }
}


/* These are used by the blogs only as they are stored in the database so I wanted stable class names, not dynamically created. */

/* General Styling */
.blog-textAlignLeft {
  text-align: left;
  margin: 20px 0;
}

.blog-text {
  font-size: 16px;
  color: #264653; 
  margin-right: 5px;
}

.blog-styledLink {
  text-decoration: none;
  color: #E76F51; 
  font-weight: 800;
  transition: color 0.3s ease, transform 0.3s ease;
}

.blog-styledLink:hover {
  color: #D9534F; 
  transform: scale(1.05);
}

/* Image Styling */
.blog-imageSmall, .blog-imageMedium, .blog-imageLarge {
  margin-bottom: 20px;
  border-radius: 8px;
}

.blog-imageSmall {
  width: 10%;
  height: 10%;
}

.blog-imageMedium {
  width: 40%;
  height: 40%;
}

.blog-imageLarge {
  width: 60%;
  height: 60%;
}

.blog-linkImageSmall {
  width: 10%;
  height: 10%;
}

.blog-linkImageMedium {
  width: 40%;
  height: 40%;
}

.blog-linkImageLarge {
  width: 60%;
  height: 60%;
}

.blog-linkImageMedium:hover, .blog-linkImageLarge:hover {
  transform: scale(1.05);
}

.blog-linkImageMedium:hover, .blog-linkImageLarge:hover {
  transform: scale(1.05);
}

/* Container for the image */
.blog-imageContainer {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.blog-imageContainer img {
  border-radius: 10px;
  #transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease; 
}

.blog-imageContainer img:hover {
  #transform: scale(1.02); 
  border: 3px solid #2A9D8F; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}

.blog-productLink {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.blog-productDescription {
  margin: 0;
  font-size: 14px;
  color: #333;
  text-align: center;
}

/* Image Alignment */
.blog-alignLeft {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.blog-alignRight {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

.blog-alignCenter {
  display: block;
  margin: 0 auto 10px auto;
}

/* Image Titles */
.blog-imageTitleCenter, .blog-imageTitleLeft, .blog-imageTitleRight {
  font-size: 14px;
  font-style: italic;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.05em;
  margin: 10px 0; 
  display: inline-block;
  background-color: #E0E0E0;
  padding: 3px 6px; 
  border-bottom: 2px solid #ccc; 
}

@media (max-width: 768px) {
  .blog-imageTitleCenter, .blog-imageTitleLeft, .blog-imageTitleRight {
    font-size: 12px;
  }
}

/* Call to Action (CTA) */
.blog-ctaContainer {
  background-color: #F4A261; 
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 20px 0;
}

.blog-ctaText {
  color: #264653; 
  font-size: 16px;
  margin-bottom: 10px;
}

.blog-ctaButton {
  text-decoration: none;
  color: #FFF; 
  background-color: #2A9D8F;
  padding: 12px 24px;
  font-weight: 800;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.blog-ctaButton:hover {
  background-color: #21867A; 
  transform: scale(1.05);
}

/* Style for in-text citation */
cite {
    font-style: normal;
    color: #007acc;
    cursor: pointer;
}

/* Style for the references section */
.blog-references {
    list-style: none;
    padding-left: 0;
}

.blog-references li {
    margin-bottom: 10px;
}

.blog-references a {
    text-decoration: none;
    color: #007acc;
}

.blog-references a:hover {
    text-decoration: underline;
}




/* These are used to make the screens with less content, take up the full height
so that the footer is at the bottom. 

How to use: <main className={`container ${styles.container}`}> 
That will pick up the global container style plus the local. */
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 1;
}

.Home_content__26mY_ {
  display: flex;
  flex-wrap: nowrap;
  font-family: Lato, sans-serif;
}

@media (max-width: 1000px) {
  .Home_content__26mY_ {
    padding: 0;
    flex-direction: column;
  }
}

.Home_title__2892A {
  font-weight: 600;
  font-size: 32px;
  color: #2A9D8F;
  margin-bottom: 20px;
}

.Home_title__2892A strong {
  font-weight: 800;
}

.Home_introContainer__jgjgH {
  flex: 2 1;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  order: 1;
  font-family: Lato, sans-serif;

  @media (max-width: 1000px) {
    flex: 0 0 100%;
    padding-bottom: 0px;
  }

}

.Home_centered__37zjS {
  margin: auto 0;
  flex: 0 0 100%;
}

.Home_introText__1G08k {
  margin-bottom: 25px;
  font-size: 18px;

  @media(max-width: 768px) {
    margin-bottom: 10px;
    margin-top: 0px;
  }

}

.Home_startButton__1LpW0 {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 22px;
  background-color: #2A9D8F;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: Lato, sans-serif;
}

.Home_startButton__1LpW0:hover {
  background-color: #1E7767;
}

.Home_startButton__1LpW0:active {
  transform: scale(0.95);
}

.Home_sidebar__1uNgD {
  flex: 1 1;
  padding: 20px;
  box-sizing: border-box;
  order: 2;

  @media (max-width: 1000px) {
    flex: 0 0 100%;
  }
}

.Home_styledLink__1Te7Q {
  text-decoration: none;
  color: #2A9D8F;
  font-weight: 600;
  transition: all 0.3s ease;
}

.Home_styledLink__1Te7Q:hover {
  text-decoration: underline;
}

.Home_centeredContainer__2Pw9S {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.BlogList_blogListContainer__5GdOQ {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    padding: 40px 10px;
    font-family: 'Lato', sans-serif;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}

.BlogList_title__fMkrZ {
    font-weight: 600;
    font-size: 24px;
    color: #2A9D8F;
    text-align: center;
}

.BlogList_title__fMkrZ strong {
    font-weight: 800;
}

.BlogList_blogItemLink__2bFZX {
    text-decoration: none;
    color: inherit;
    width: 100%; /* Makes the entire item clickable */
    max-width: 800px;
    margin: 0 auto;
}

.BlogList_blogItem__1ogyB {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #f5f5f5;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Adds scaling effect */
    cursor: pointer; /* Indicates the entire box is clickable */
    width: 80%;
}

.BlogList_blogItem__1ogyB:hover {
    transform: scale(1.02); /* Scales the item slightly on hover */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Enhances the shadow on hover */
}

.BlogList_blogTitle__2jEIy {
    margin-bottom: 15px;
    text-align: center;
}

.BlogList_blogSnippet__c2swj {
    text-align: justify;
    margin-bottom: 20px;
}

.BlogList_readMoreLink__2EJ7o {
    color: #3498db;
    cursor: pointer;
    text-decoration: none;
    align-self: center;
    font-weight: 600;
}

.BlogList_readMoreLink__2EJ7o:hover {
    text-decoration: underline;
}

.BlogList_blogTopic__3Mhl7 {
    background-color: #f5f5f5;
    padding: 5px;
    border-radius: 3px;
    font-size: 0.9rem;
    margin-bottom: 15px;
}

.BlogList_imageContainer__3Vjz0 {
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}

.BlogList_blogImage__26L_m {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
}

@media only screen and (max-width: 800px) {
    .BlogList_blogListContainer__5GdOQ {
        padding: 20px;
    }

    .BlogList_title__fMkrZ {
        font-size: 20px;
    }

    .BlogList_blogItem__1ogyB {
        width: 90%;
    }
}

.BlogList_section__3P-FM {
    margin-bottom: 50px;
}

.QuestionGame_container__3UdMx {
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #fff0e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.QuestionGame_title__2evD9 {
    font-size: 24px;
    margin-bottom: 10px;
}

.QuestionGame_description__13h3d {
    font-size: 14px;
    margin-bottom: 20px;
}

.QuestionGame_questionText__2VQWc {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.QuestionGame_answerButton__2dd3X {
    margin: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.QuestionGame_answerButton__2dd3X:hover {
    background-color: #1E7767;
}

.QuestionGame_correctAnswer__169EK {
    font-size: 16px;
    color: green;
    font-weight: bold;
}

.LifestyleTips_container__1kYAy {
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #e6f7ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  margin-bottom: 20px;
  font-family: Lato, sans-serif;
}

.LifestyleTips_tipHeader__1NQBo h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.LifestyleTips_tipText__3aUB3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.LifestyleTips_tipButton__1x6gu {
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #2A9D8F;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.LifestyleTips_tipButton__1x6gu:hover {
  background-color: #1E7767;
}


.LifestyleTips_description__3nu5M {
  font-size: 14px;
  margin-bottom: 20px;
}


.LifestyleTips_answer__JYzzR {
  font-size: 16px;
  color: green;
  font-weight: bold;
}

.GameComponent_container__2xVNd {
    margin-top: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    align-items: center; 
    background-color: #e6ffe6;
    border: 2px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 769px) {
        #align-items: flex-start;
    }
}

.GameComponent_styledPhaserGameWrapper__2duT7 {
    border: 1px solid #e0e0e0;
    padding: 5px;
    border-radius: 5px;
    width: 362px;
    height: 510px;
    position: relative;
}

.GameComponent_title__RffR2 {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 24px;
    text-align: center;
}

.GameComponent_buttonContainer__1gGMP {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.GameComponent_gameControls__2S8OZ {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;

    @media (max-width: 768px) {
        grid-gap: 10px;
        gap: 10px;
    }
}

.GameComponent_controlButton__2OefO {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    cursor: pointer;

    @media (max-width: 768px) {
        padding: 12px 20px;
        width: 100%;
    }
}

.GameComponent_controlButton__2OefO:hover {
    background-color: #1E7767;
}


.GameComponent_controlButton__2OefO:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
}

.GameComponent_gameStateInfo__2IadI {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.GameComponent_healthBar__1MRgV {
    height: 20px;
}

.GameComponent_green__3VdpY {
    background-color: green;
}

.GameComponent_red__2ubm0 {
    background-color: red;
}

.GameComponent_mushroomImagePlaceholder__-vbXO {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-left: 10px;
}

.GameComponent_soundToggle__hdnvr {
    cursor: pointer;
    margin-right: 10px;
}

.GameComponent_titleBar__213Qm {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.Counters_countersContainer__3Oia1 {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    max-width: 800px;
    margin: 0 auto;
    grid-gap: 20px;
    gap: 20px;
    font-family: Lato, sans-serif;
}

.ReportCounter_counterContainer__1jQM- {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    background-color: #e6f7ff;
    flex: 1 1; 
    min-width: 150px; 
    text-align: center; 
}

.ReportCounter_reportCount__1EUA0 {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.ReportCounter_description__2sHAd {
    font-size: 18px;
    color: #777;
}

.HappyCustomers_counterContainer__9cz-p {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    background-color: #e6f7ff;
    flex: 1 1; 
    min-width: 150px; 
    text-align: center; 
}

.HappyCustomers_customerCount__INX1G {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.HappyCustomers_description__S7t63 {
    font-size: 18px;
    color: #777;
}

.Testimonial_testimonialContainer__3QXey {
    padding: 20px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.Testimonial_userImage__36uWt {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 15px;
}

.Testimonial_userName__1LOVI {
    margin-bottom: 10px;
    font-size: 20px;
}

.Testimonial_userTestimony__3aOU7 {
    font-size: 16px;
    text-align: center;
    line-height: 1.6;
}

.UserFeedback_feedbackContainer__iyq1Y {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #fff0e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.UserFeedback_feedbackText__W85Ri {
    font-family: Lato, sans-serif;
    font-size: 1em;
    margin-bottom: 10px;
    margin-top: 0px;
}

.UserFeedback_buttonGroup__3FP3X {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}

.UserFeedback_feedbackButton__K39om {
    padding: 5px 15px;
    font-size: 14px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Lato, sans-serif;
}

.UserFeedback_feedbackButton__K39om:hover {
    background-color: #1E7767;
}

.UserFeedback_feedbackLink__2CTYs {
    font-size: 14px;
    margin-top: 10px;
    text-decoration: none;
    color: #2A9D8F;
}

.UserFeedback_feedbackLink__2CTYs:hover {
    text-decoration: underline;
}

.Journey_container__3yaQ4 {
    @media (max-width: 768px) {
        padding: 50px 20px;
    }
}

.Journey_journeyContainer__1eeui {
    max-width: 800px;
    margin: 50px auto;
    text-align: center;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        margin: 30px auto;
    }
}

.Journey_title__3EwU7 {
    font-size: 2.5rem;
    color: #2A9D8F;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 2rem;
        margin-bottom: 10px;
        margin-top: 40px;
    }
}

.Journey_description__tGFF8 {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.Journey_highlight__35m7X {
    font-weight: bold;
    color: #1E7767;
}

.Journey_signupButton__l1LQj {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 22px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.Journey_signupButton__l1LQj:hover {
    background-color: #1E7767;
}

.Journey_signupButton__l1LQj:active {
    transform: scale(0.95);
}

.PartialReportResponse_container__1-EfD {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
}

.PartialReportResponse_title__1fY8J {
    text-align: center;
    margin-bottom: 20px;
}

.PartialReportResponse_score__1h9vs {
    font-size: 2rem;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.PartialReportResponse_summary__2bZWP {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
}


.GetReport_questionsContainer__1oLkl {
    padding: 20px;
    font-family: Lato, sans-serif;
}

.GetReport_questionRow__2W5EW {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        grid-gap: 8px;
        gap: 8px;
        align-items: stretch; 
    }
}

.GetReport_questionLabel__2VqMh {
    display: flex;
    align-items: center;
    flex: 1 1;
    min-width: 0;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        flex-direction: column; 
    }
}

.GetReport_questionText__1S24n {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1 1;
    min-width: 0;

    @media (max-width: 768px) {
        white-space: normal;
        text-align: center;
        width: 100%; 
    }
}

.GetReport_questionAnswer__3wmn0 {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    flex-shrink: 0;

    @media (max-width: 768px) {
        width: 100%; 
    }
}

.GetReport_generateReportButton__1YgFt {
    font-size: 18px;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.GetReport_generateReportButton__1YgFt:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
}

.GetReport_generateReportButton__1YgFt:hover {
    background-color: #0056b3;
}

.GetReport_serverResponse__2DHCN {
    margin-top: 20px;
}

.GetReport_infoMessage__1Xsq1 {
    font-size: 16px;
    color: #888;
    margin-top: 10px;
    text-align: center;
}

.GetReport_startAgainButton__1Ped9 {
    font-size: 18px;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #C9E4DE;
    color: #2A5A59;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.GetReport_startAgainButton__1Ped9:hover {
    background-color: #B0D0CC;
}

.GetReport_buttonGroup__YyUSt {
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px; 
}

.GetReport_serverErrorMessage__1Ycmj {
    color: red;
    border: 1px solid red;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
}

.GetReport_paginationContainer__CPHTJ {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    background-color: #fff0e6;
}

.GetReport_pageButton__18OhB {
    padding: 10px 20px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}

.GetReport_pageButton__18OhB:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.GetReport_progressBarContainer__2gkpw {
    width: 100%;
    background-color: #fff0e6;
    border-radius: 5px;
    margin: 15px 0;
    height: 20px;
    position: relative;
}

.GetReport_progressBarFiller__2PAc0 {
    background-color: blue;
    height: 100%;
    border-radius: inherit;
    transition: width 0.3s ease-in-out;
}

.GetReport_progressText__1kPo2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.9rem;
    color: black;
}

.GetReport_pageIndicatorContainer__2SP2R {
    padding: 10px 20px;
    background-color: #fff0e6;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.9rem;
    display: inline-block;
    margin: 10px 0;
}

.GetReport_spinnerContainer__3459x {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    flex-direction: column;
}

.GetReport_randomMessage__2dRf4 {
    margin-top: 25px;
}

.Questions_questionsContainer__3hQhu {
    padding: 20px;
    font-family: Lato, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    font-family: Lato, sans-serif;
}

.Questions_fetchButton__83mQD {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.Questions_fetchButton__83mQD:hover {
    background-color: #1E7767;
}

.Questions_fetchButton__83mQD:active {
    transform: scale(0.95);
}

.Questions_fetchButton__83mQD:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.Nav_nav__2oQYS {
    padding: 20px;
    background-color: #00796B;
    display: flex;
    justify-content: space-between;
    font-family: Lato, sans-serif;
}

@media (max-width: 768px) {
    .Nav_nav__2oQYS {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
}

.Nav_navItem__3ANtz {
    color: #FAFAFA;
    font-size: 1rem;
    flex: 1 1;
    text-transform: uppercase;
    text-decoration: none;
    margin-right: 20px;
    padding: 5px 10px;
    border-radius: 5px;
}

.Nav_navItem__3ANtz:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
    .Nav_navItem__3ANtz {
        font-size: 0.8rem;
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.Nav_navItemBrand__hOZdz {
    font-size: 2rem;
    flex: 1 1;
}

.Nav_navItemBrand__hOZdz:hover {
    background-color: transparent;
}

@media (max-width: 768px) {
    .Nav_navItemBrand__hOZdz {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }
}

.Nav_separator__2QJ_c {
    display: none;
    color: #FAFAFA;
    padding: 0 5px;
}

@media (max-width: 768px) {
    .Nav_separator__2QJ_c {
        display: none;
    }
}

.Nav_signupButton__2szFn {
    font-family: Lato, sans-serif;
    padding: 5px 15px;
    background-color: #029A82;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    margin-top: 3px;
    margin-left: 5px;
    margin-bottom: 10px;
    
    @media (max-width: 768px) {
        padding: 3px 10px;
        margin-left: 15px;
        font-size: 0.8rem;
    }
}

.Nav_signupButton__2szFn:hover {
    background-color: #CE6F72;
}

.Nav_loginButton__3-oSz {
    padding: 5px 15px;
    background-color: #029A82;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    
}

.Nav_buttonContainer__1zqLV {
    margin-top: 5px;
    margin-left: 3px;
}

.Nav_welcomeMessageStyle__1oN3A {
    color: #FAFAFA;
    margin-right: 10px;
}

.Nav_profileBlock__tlH-O {
    flex: 1 1;
    border: 1px solid #444;
    margin-right: 5px;

    @media (max-width: 768px) {
        border: none;
    }
}

.Nav_basketIcon__ZRGtv {
    position: relative;
    cursor: pointer;
}

.Nav_basketIconCount__banaw {
    position: absolute;
    top: -8px;
    right: 2px;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
}

.Nav_styledBasketImage__2-WKO {
    width: 30px;
}

.Nav_hiddenBasket__UcSYr {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.Nav_mushroomIcon__3FVKs {
    position: relative;
    cursor: pointer;
    margin-left: 3px;
}

.Nav_styledMushroomImage__1F0Cz {
    width: 30px;
    height: 30px;
}

.Nav_hiddenMushroom__1QNvE {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.LogoutComponent_styledButton__2AqTL {
    text-align: left;
    padding: 12px 16px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: inherit;
    color: inherit;
    width: 100%;
    background-color: #f1f1f1;
    margin-bottom: 3px;
    border-radius: 6px;
    font-family: Lato, sans-serif;
  }
  
  .LogoutComponent_styledButton__2AqTL:hover {
    background-color: #C5B3B3;
  }

.LoginComponent_loginContainer__kHtZD {
    align-items: center;
    padding: 10px;
    font-family: Lato, sans-serif;
    
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.LoginComponent_inputField__3MA3o {
    padding: 5px 10px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    width: 100%;
    max-width: 200px;

    @media (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 0px;
    }
}

.LoginComponent_inputField__3MA3o:focus {
    border-color: blue;
}

.LoginComponent_loginButton__1w3Jl {
    font-family: Lato, sans-serif;
    padding: 5px 18px;
    background-color: #029A82;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    

    @media (max-width: 768px) {
        margin-bottom: 100px;
    }
    
}

.LoginComponent_loginButton__1w3Jl:hover {
    background-color: #CE6F72;
}

.LoginComponent_logoutContainer__1rk7o {
    display: inline-block;
    margin-left: 10px;
}

.LoginComponent_loginLink__3O4E3 {
    display: none;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 5px;
    color: white;
    background: transparent;
    border: none;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1em;
}

.LoginComponent_loginLink__3O4E3:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
    .LoginComponent_showFields__15jHU {
        display: block;
    }

    .LoginComponent_hideFields__1nnPT {
        display: none;
    }
}

.ProfileDropdown_profileMenu__2OVUz {
  position: relative;
  display: inline-block;
  padding-right: 30px;
  font-family: Lato, sans-serif;

  @media (max-width: 769px) {
    margin-bottom: 10px;
  }
}

.ProfileDropdown_dropdownContent__2DnGM {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  border: 1px solid #ccc;
  z-index: 8;
  border-radius: 4px;
  font-size: 0.7em;
}

.ProfileDropdown_dropdownContent__2DnGM a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.ProfileDropdown_dropdownContent__2DnGM a:hover {
  background-color: #f1f1f1;
}

.ProfileDropdown_show__2jKRA {
  display: block;
}

.ProfileDropdown_profileButton__1YQg1 {
  padding: 5px 5px;
  background-color: #029A82;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.9em;
}

.ProfileDropdown_profileButton__1YQg1:hover {
  background-color: #CE6F72;
}

.ProfileDropdown_styledButton__3tN8t {
  text-align: left;
  padding: 12px 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  color: inherit;
  width: 100%;
  background-color: #f1f1f1;
  margin-bottom: 3px;
  border-radius: 6px;
}

.ProfileDropdown_styledButton__3tN8t:hover {
  background-color: #C5B3B3;
}

.ProfileDropdown_welcomeMessage__3xJsk {
  font-weight: 600;
  text-align: center;
  border-bottom: 0.5px solid black;
  margin: 5px;
  padding-bottom: 5px;
}

.ProfileDropdown_mushroom__EU2tJ {
  font-weight: 400;
  text-align: left;
  margin: 5px;
  margin-top: 20px;
}

.ProfileDropdown_mushroomIcons__c2n9S {
  font-weight: 400;
  text-align: left;
  margin: 5px;
}

.ProfileDropdown_mushroomCount__16iPz {
  font-size: 0.8em;
  margin-right: 10px;
}

.MushroomBronzeIcon_small__3ViY4 {
    position: relative;
    max-width: 20px;
}

.MushroomBronzeIcon_extraSmall__3osCN {
    position: relative;
    max-width: 15px;
}

.MushroomSilverIcon_small__1ZQgz {
    position: relative;
    max-width: 20px;
}

.MushroomSilverIcon_extraSmall__2QNEn {
    position: relative;
    max-width: 15px;
}

.MushroomGoldIcon_small__2XCr1 {
    position: relative;
    max-width: 20px;
}

.MushroomGoldIcon_extraSmall__12yJn {
    position: relative;
    max-width: 15px;
}

.Footer_footer__1qaN6 {
    padding: 20px 20px;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid #444;
    font-family: Lato, sans-serif;
}

@media (max-width: 768px) {
    .Footer_footer__1qaN6 {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.Footer_linkGroup__1GOnH {
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    padding: 10px 0;
}

@media (max-width: 768px) {
    .Footer_linkGroup__1GOnH {
        margin: 0;
    }
}

.Footer_linkGroup__1GOnH:first-child {
    margin-left: 0;
}

.Footer_linkGroup__1GOnH:last-child {
    margin-right: 0;
}

.Footer_styledLink__1gV2g {
    margin-bottom: 10px;
    font-size: 0.8em;
    color: #ddd;
    text-decoration: none;
    transition: color 0.3s;
}

.Footer_styledLink__1gV2g:hover {
    color: #fff;
}

.Footer_socialMediaGroup__18F4Q {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.Footer_socialMediaLink__3rXWB {
    margin: 0 15px;
    transition: transform 0.3s;
}

.Footer_socialMediaLink__3rXWB:first-child {
    margin-left: 0;
}

.Footer_socialMediaLink__3rXWB:last-child {
    margin-right: 0;
}

.Footer_socialMediaLink__3rXWB:hover {
    transform: scale(1.1);
}

.Footer_copyrightText__2ngUn {
    margin-top: 30px;
    flex-basis: 100%;
    text-align: center;
    font-size: 0.8em;
    color: #aaa;
}

.SignupComponent_container__2gvhu {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: Lato, sans-serif;
}

.SignupComponent_styledInput__1ETDO {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;

    @media (min-width: 768px) {
        font-size: 14px;
        width: auto;
    }
}

.SignupComponent_signupButton__37VMr {
    font-family: Lato, sans-serif;
    padding: 10px 20px;
    font-size: 22px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.SignupComponent_signupButton__37VMr:hover {
    background-color: #1E7767;
}

.SignupComponent_styledLabel__357uQ {
    font-size: 16px;
    font-weight: bold;
    width: 190px;
    text-align: right;
    margin-right: 10px;

    @media (max-width: 768px) {
        text-align: left;
        text-size: 14px;
    }
}

.SignupComponent_mandatoryMarker__1jfzS {
    color: red;
}

.SignupComponent_signupDescription__1HxaB {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 20px;
    color: #2A9D8F;

    @media (min-width: 768px) {
        text-align: left;
    }
}

.SignupComponent_inputGroup__3X5Dw {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }

}

.SignupComponent_styledSelect__3ZTev {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-appearance: none;
            appearance: none;
    font-size: 14px;
    width: 100%;

    @media (min-width: 769px) {
        width: auto;
    }
}

.SignupComponent_styledSelect__3ZTev:focus {
    border-color: #007bff;
}

.SignupComponent_mandatoryNote__AX0x_ {
    font-size: 0.9rem;
    text-decoration: none;
    color: #666;
    margin-top: 10px;
}

.SignupComponent_title__1HNbb {
    margin: 0 auto;
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.SignupComponent_validationBlock__wrLw2 {
    color: red;
    font-size: 0.8em;
    margin-left: 5px;
}

.SignupComponent_cancelButton__27xVe {
    font-size: 18px;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #C9E4DE;
    color: #2A5A59;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.SignupComponent_cancelButton__27xVe:hover {
    background-color: #B0D0CC;
}

.PrivacyPolicy_container__KGWwh {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.PrivacyPolicy_title__4A_BT {
    text-align: center;
    margin-bottom: 20px;
}

.PrivacyPolicy_sectionTitle__3Vgz3 {
    margin-top: 20px;
}

.PrivacyPolicy_paragraph__3QoDa {
    margin-bottom: 15px;
    line-height: 1.5;
}

.PrivacyPolicy_emailLink__1j1xk {
    margin-top: 10px;
    text-decoration: none;
    color: #2A9D8F;
}

.PrivacyPolicy_emailLink__1j1xk:hover {
    text-decoration: underline;
}

.PrivacyPolicy_link__1vhoL {
    margin-top: 10px;
    text-decoration: none;
    color: #2A9D8F;
}

.PrivacyPolicy_link__1vhoL:hover {
    text-decoration: underline;
}

.FAQs_container__1Q4eD {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.FAQs_title__FSfsI {
    text-align: center;
    margin-bottom: 20px;
}

.FAQs_questionContainer__2lSWd {
    margin-top: 20px;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 10px;
}

.FAQs_question__3WG1L {
    margin: 0;
}

.FAQs_answer__21zKn {
    display: none;
    margin-top: 10px;
}

.FAQs_answerOpen__2cgtr {
    display: block;
}

.FAQs_link__2sAx7 {
    text-decoration: none;
    color: #2A9D8F;
    font-weight: 600;
}

.FAQs_link__2sAx7:hover {
    text-decoration: underline;
}

.FAQs_feedback__2fFga {
    width: 25%;
    margin: 0 auto;
}

.ContactUs_container__34grx {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.ContactUs_title__rXZZS {
    text-align: center;
    margin-bottom: 20px;
}

.ContactUs_form__xspqt {
    display: flex;
    flex-direction: column;
}

.ContactUs_input__2ATx5 {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 16px;
}

.ContactUs_textarea__1vrnO {
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
    min-height: 100px;
    margin-bottom: 10px;
}

.ContactUs_submitButton__2kq4O {
    padding: 10px 20px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1em;
}

.ContactUs_submitButton__2kq4O:hover {
    background-color: #1E7767;
}

.ContactUs_submitButton__2kq4O:active {
    transform: scale(0.95);
}

.ContactUs_feedbackText__qwua_ {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;  
}

.ContactUs_mushroomIconWrapper__1A-Wl {
    position: relative;
    width: 40px; 
    height: 40px; 
    display: inline-block;
    vertical-align: middle; 
}

.ContactUs_userFeedback__3YJNV {
    margin-top: 30px;
}

.AboutUs_container__23Jpr {
    padding: 20px;
    background-color: #f4f4f4;
    font-family: Lato, sans-serif;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}

@media (max-width: 768px) {
    .AboutUs_container__23Jpr {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.AboutUs_title__3XuM- {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.AboutUs_title__3XuM- strong {
    font-weight: 800;
}

.AboutUs_description__3LB1w {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.5;
}

.AboutUs_description__3LB1w strong {
    font-weight: 800;
}

.AboutUs_bullet__3SyUX {
    font-weight: bold;
    margin-left: 20px;
    text-align: left;
}

.AboutUs_feedback__2y3_t {
    width: 35%;
    margin: 0 auto;
}

.AboutUs_section__1JXFL {
    margin-bottom: 50px;
}

.Subscriptions_container__2YCUo {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f7f7f7;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.Subscriptions_title__IoVq3 {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.Subscriptions_title__IoVq3 strong {
    font-weight: 800;
}

.Subscriptions_pricingCard__3LEko {
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: rgba(205, 127, 50, 0.5);
    padding: 10px;
    margin: 0 20px;
    width: 350px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
    font-size: 0.9em;

    @media (max-width: 768px) {
        margin-bottom: 10px;
    }
}

.Subscriptions_pricingCard__3LEko:hover {
    transform: scale(1.05);
}

.Subscriptions_silverMonthlyPricingCard__3zaZ4 {
    background-color: rgba(192, 192, 192, 0.5);
}

.Subscriptions_goldMonthlyPricingCard__CWPJl {
    background-color: rgba(255, 215, 0, 0.5);
}

.Subscriptions_tierTitle__zx8Ge {
    text-align: center;
    margin-bottom: 20px;
}

.Subscriptions_price___EDTd {
    font-size: 24px;
    text-align: center;
}

.Subscriptions_priceStrike__v3Rit {
    text-decoration: line-through;
}

.Subscriptions_featureList__3lwRP {
    list-style-type: none;
    padding: 0;
}

.Subscriptions_feature__1FP75 {
    margin-bottom: 10px;
    text-align: left;
    font-size: 0.9em;
}

.Subscriptions_subscribeButton__kay1Q {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.Subscriptions_subscribeButton__kay1Q:hover {
    background-color: darkblue;
}

.Subscriptions_subscribeButton__kay1Q:disabled {
    background-color: gray;
    cursor: not-allowed;
}

.Subscriptions_note__3aZLu {
    text-align: center;
    font-style: italic;
}

.Subscriptions_introParagraph__2hIR9 {
    text-align: center;
    font-size: 18px;
    max-width: 800px;
    margin: 0 auto 40px;
    line-height: 1.6;
    color: #333;
}

.Subscriptions_pricingCardContainer__VLy33 {
    display: flex;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;
    }
}

.Subscriptions_concludingParagraph__3WGfa {
    text-align: center;
    font-size: 18px;
    max-width: 800px;
    margin: 40px auto 0;
    line-height: 1.6;
    color: #333;

    @media (max-width: 768px) {
        margin: 0px auto 0;
    }
}

.SarahIntro_sarahIntroContainer__leqUO {
    padding: 20px;
    font-family: Lato, sans-serif;
    background-color: #ffe6f9;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Lato, sans-serif;
    
    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.SarahIntro_title__ptAJR {
    color: #ff66cc;
    font-weight: bold;
    text-align: center;
}

.SarahIntro_introText__3UzeB {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    margin-bottom: 20px;
}

.SarahIntro_imageContainer__xIINq {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.SarahIntro_sarahImage__odgcc {
    max-width: 200px;
    max-height: 200px;
    border-radius: 50%;
}

.Blog_blogContainer__2b52E {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 40px auto;
  font-family: Lato, sans-serif;
}

.Blog_blogTitle__21xMX {
  text-align: center;
  margin-bottom: 20px;
  font-size: 36px;
}



.Blog_blogContent__1_Ddj {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.Blog_blogFooter__3gjW4 {
  margin-top: 20px;
  font-style: italic;
  font-size: 14px;
  color: #555;
}

@media only screen and (max-width: 768px) {
  .Blog_blogTitle__21xMX {
    font-size: 28px;
  }

  .Blog_blogContainer__2b52E {
    padding: 15px;
    max-width: 95%;
  }

  .Blog_blogContent__1_Ddj,
  .Blog_blogFooter__3gjW4 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .Blog_blogTitle__21xMX {
    font-size: 28px;
  }

  .Blog_blogContainer__2b52E {
    padding: 10px;
  }

  .Blog_blogContent__1_Ddj,
  .Blog_blogFooter__3gjW4 {
    font-size: 16px;
  }
}

.Blog_commentSection__3JIrS {
  margin-top: 40px;
}

.Blog_videoTitle__3HDzU {
text-align: center;
    font-size: 14px;
    font-style: italic;
    color: darkgray;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.05em;
    margin: 10px 0; 
    display: inline-block;
    background-color: #f8f8f8; 
    padding: 3px 6px; 
    border-bottom: 2px solid #ccc; 
    

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.Blog_loginPrompt__1y5b1 {
  color: #666;
  font-size: 1rem;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}

.Blog_styledLink__1mZRu {
  text-decoration: none;
  color: #2A9D8F;
  font-weight: 800;
  transition: all 0.3s ease;
}

.Blog_styledLink__1mZRu:hover {
  text-decoration: underline;
}

.UserStarFeedback_feedbackContainer__2Ohuy {
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #e6f7ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    text-align: center;
    font-family: Lato, sans-serif;
}

.UserStarFeedback_feedbackText__3Vxy1 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0px;
}

.UserStarFeedback_star__zYmCh {
    font-size: 24px;
    margin: 0 5px;
    cursor: pointer;
}

.UserStarFeedback_star__zYmCh.UserStarFeedback_active__2rWVu {
    color: gold;
}

.UserStarFeedback_submitButton__AUg1T {
    font-family: Lato, sans-serif;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.UserStarFeedback_submitButton__AUg1T:hover {
    background-color: #1E7767;
}

.CommentsList_commentsList__32QeW {
    margin-top: 20px;
    border-top: 1px solid #eee;
    padding-top: 20px;
  }
  
  .CommentsList_commentItem__inO2D {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
  }
  
  .CommentsList_commentAuthor__2xjsg {
    font-size: 0.8em;
    font-weight: bold;
    margin-right: 5px;
  }
  
  .CommentsList_commentText__A6mFA {
    font-size: 0.8em;
    text-align: left;
    white-space: pre-line; /* This ensures that line breaks are honored */
  }

  .CommentsList_noComments__2YOsa {
    font-size: 0.8em;
  }

.CommentForm_commentForm__2-bfJ {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.CommentForm_commentTextarea__1oUPN {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 100px;
    resize: vertical;
}

.CommentForm_commentButton__pArVt {
    padding: 10px 20px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
    font-size: 1em;
  }
  
  .CommentForm_commentButton__pArVt:hover {
    background-color: #1E7767;
  }
  
  .CommentForm_commentButton__pArVt:active {
    transform: scale(0.95);
  }

.VideoWrapper_container__1w2xf {
  width: calc(100% - 20px);  
  height: 0;
  padding: 10px;  
  padding-bottom: calc(56.25% - 20px);  /* 16:9 Aspect Ratio, adjusted for 20px vertical padding */
  position: relative;
}

.VideoWrapper_videoFrame__2Y3HX {
  position: absolute;
  top: 10px;  
  left: 10px; 
  width: calc(100% - 20px);  /* Adjusted for side padding */
  height: calc(100% - 20px);  /* Adjusted for top and bottom padding */
  border: 0;
}

.AdminAddBlog_container__3xOOd {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.AdminAddBlog_title__24oca {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.AdminAddBlog_form__2j_vh {
    width: 80%;
}

.AdminAddBlog_formField__2Cv1A {
    margin-bottom: 20px;
}

.AdminAddBlog_label__2q-Uj {
    display: block;
    margin-bottom: 10px;
}

.AdminAddBlog_input__1cM9E {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.AdminAddBlog_textarea__3kCmg {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    height: 1500px;
}

.AdminAddBlog_select___fTRm {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.AdminAddBlog_button__mMfZX {
    padding: 10px 20px;
    margin-right: 5px;
    font-size: 16px;
    cursor: pointer;
}

.AdminAddBlog_previewArea__1rpXq {
    width: 100%;
    height: 250px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    overflow-y: auto;  
    white-space: pre-wrap;  
    word-wrap: break-word;  
}

.AdminAddBlog_buttonGroup__2AuMe {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;  
}

.AdminAddBlog_checkboxLabel__1b4gX {
    font-size: 0.9rem;
}

.AdminAddBlog_dropdown__2P-JY {
    display: block;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 100; /* to ensure it overlays other elements */
}

.AdminAddBlog_dropdown__2P-JY button {
    display: block;
    width: 100%;
    padding: 5px 10px;
    background-color: transparent;
    border: none;
    text-align: left;
}

.AdminAddBlog_secondBtnRow__121Yl {
    margin-top: 5px;
}

.AdminListBlogs_container__1W11R {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.AdminListBlogs_title__3eWHp {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.AdminListBlogs_blogItem__IIT5t {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.AdminListBlogs_blogTitle__fPhl7 {
    flex: 3 1;
    font-size: 14px;
}

.AdminListBlogs_blogAttrib__2CJqV {
    flex: 1 1;
    font-size: 14px;
    color: #777;
}

.AdminListBlogs_actionButton__2h1GN {
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
}

.AdminListBlogs_actionButton__2h1GN:hover {
    background-color: #1E7767;
}

.AdminListBlogs_actionButton__2h1GN:active {
    transform: scale(0.95);
}

.AdminListBlogs_blogHeader__2Torz {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.AdminListBlogs_blogHeaderTitle__BUbIL {
    flex: 3 1;
    font-size: 14px;
    font-weight: 800;
}

.AdminListBlogs_blogHeaderAttrib__2M6r8 {
    flex: 1 1;
    font-size: 14px;
    font-weight: 800;
}

.AdminListComments_container__2B4mF {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.AdminListComments_title__2G9wr {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.AdminListComments_commentItem__16pi6 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.AdminListComments_commentTitle__rHM4F {
    flex: 3 1;
    font-size: 14px;
}

.AdminListComments_commentAttrib__37BcI {
    flex: 1 1;
    font-size: 14px;
    color: #777;
}

.AdminListComments_actionButton__lVLFa {
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
}

.AdminListComments_actionButton__lVLFa:hover {
    background-color: #1E7767;
}

.AdminListComments_actionButton__lVLFa:active {
    transform: scale(0.95);
}

.AdminListComments_commentHeader__3Bv0e {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.AdminListComments_commentHeaderTitle__2nnG5 {
    flex: 3 1;
    font-size: 14px;
    font-weight: 800;
}

.AdminListComments_commentHeaderAttrib__2GnHE {
    flex: 1 1;
    font-size: 14px;
    font-weight: 800;
}

.AdminListUsers_container__QroP6 {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.AdminListUsers_title__1GgW_ {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.AdminListUsers_userItem__3QJ_9 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.AdminListUsers_userTitle__3nLRm {
    flex: 3 1;
    font-size: 14px;
}

.AdminListUsers_userAttrib__1PRwV {
    flex: 1 1;
    font-size: 14px;
    color: #777;
}

.AdminListUsers_actionButton__2My7_ {
    padding: 5px 10px;
    margin-left: 10px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
}

.AdminListUsers_actionButton__2My7_:hover {
    background-color: #1E7767;
}

.AdminListUsers_actionButton__2My7_:active {
    transform: scale(0.95);
}

.AdminListUsers_userHeader__2o441 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #eee;
}

.AdminListUsers_userHeaderTitle__1vuqI {
    flex: 3 1;
    font-size: 14px;
    font-weight: 800;
}

.AdminListUsers_userHeaderAttrib__2B0jX {
    flex: 1 1;
    font-size: 14px;
    font-weight: 800;
}

.EditProfile_container__3PthY {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: Lato, sans-serif;
}

.EditProfile_styledInput__27xEW {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;

    @media (min-width: 769px) {
        width: auto;
    }
}

.EditProfile_updateButton__-gu6C {
    padding: 10px 20px;
    background-color: #2A9D8F;
    font-size: 22px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.EditProfile_updateButton__-gu6C:hover {
    background-color: #1E7767;
}


.EditProfile_styledLabel__11b9x {
    font-weight: bold;
    width: 190px;
    text-align: right;
    margin-right: 10px;

    @media (max-width: 768px) {
        text-align: left;
    }
}

.EditProfile_mandatoryMarker__1Chb0 {
    color: red;
}

.EditProfile_title__1wx7C {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.EditProfile_inputGroup__24bqS {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.EditProfile_styledSelect__1IijS {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-appearance: none;
            appearance: none;
    font-size: 14px;
    width: 100%;

    @media (min-width: 769px) {
        width: auto;
    }
}

.EditProfile_styledSelect__1IijS:focus {
    border-color: #007bff;
}

.EditProfile_mandatoryNote__2KB2w {
    font-size: 0.9rem;
    text-decoration: none;
    color: #666;
    margin-top: 10px;
}

.EditProfile_validationBlock__wKad1 {
    color: red;
    font-size: 0.8em;
    margin-left: 5px;
}

.EditProfile_cancelButton__3Vb7c {
    font-size: 18px;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #C9E4DE;
    color: #2A5A59;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.EditProfile_cancelButton__3Vb7c:hover {
    background-color: #B0D0CC;
}

.YesNoModal_modalOverlay__e4VnG {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.YesNoModal_modal__3r_L0 {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.YesNoModal_buttons__29-Dn {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.YesNoModal_button__1h7iJ {
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2A9D8F;
  color: white;
}

.YesNoModal_button__1h7iJ:hover {
  background-color: #1E7767;
}

.YesNoModal_button__1h7iJ:active {
  transform: scale(0.95);
}

.YesNoModal_closeButton__2gJK4 {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.YesNoModal_closeButton__2gJK4:hover {
  color: #888;
}

.ViewProfile_container__WFNDh {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: Lato, sans-serif;
}

.ViewProfile_styledText__1vbmR {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;

    @media (min-width: 769px) {
        width: auto;
    }
}

.ViewProfile_editButton__3wH9x {
    padding: 10px 20px;
    background-color: #2A9D8F;
    font-size: 22px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.ViewProfile_editButton__3wH9x:hover {
    background-color: #1E7767;
}


.ViewProfile_styledLabel__38JdC {
    font-weight: bold;
    width: 190px;
    text-align: right;
    margin-right: 10px;

    @media (max-width: 768px) {
        text-align: left;
    }
}

.ViewProfile_title__EEhLx {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.ViewProfile_profileGroup__1eg2g {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.Dashboard_container__3GPME {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Lato, sans-serif;
}

.Dashboard_title__26Riw {
  font-weight: 600;
  font-size: 32px;
  color: #2A9D8F;
  margin-bottom: 20px;
  text-align: center;
}

.Dashboard_dashboardSection__xvO7L {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.ProgressReportGraph_container__Wulh5 {
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-family: Lato, sans-serif;
}

.ProgressReportGraph_title__1bua2 {
  font-weight: 600;
  font-size: 1.2em;
  color: #2A9D8F;
  margin-bottom: 20px;
  text-align: center;
}

.ProgressReportGraph_customLegend__1hTaQ {
  list-style: none;
  display: flex;
}

.ProgressReportGraph_customLegend__1hTaQ li {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.ProgressReportGraph_legendColourBox__3rX9g {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 4px;
}

/* Add a media query for mobile devices */
@media (max-width: 768px) { /* or another breakpoint that suits your design */
  .ProgressReportGraph_container__Wulh5 {
    padding: 10px; /* smaller padding on mobile */
  }

  .ProgressReportGraph_title__1bua2 {
    font-size: 1em; /* smaller font size on mobile */
    margin-bottom: 10px;
  }

  /* Adjust the legend styling for smaller screens */
  .ProgressReportGraph_customLegend__1hTaQ {
    flex-wrap: wrap; /* wrap legends if space is not sufficient */
    justify-content: center; /* center align the legend items */
  }

  .ProgressReportGraph_customLegend__1hTaQ li {
    margin-right: 8px; /* smaller margin between legend items */
    margin-bottom: 5px; /* space between wrapped items */
  }

  .ProgressReportGraph_legendColourBox__3rX9g {
    width: 10px;
    height: 10px;
  }
}

.Achievements_container__3bhXb {
    width: 100%;
    background-color: #e6f7ff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.Achievements_list__jCRou {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.Achievements_listItem__k9bCm {
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap on smaller screens */
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

.Achievements_listItem__k9bCm:nth-child(odd) {
    background-color: #f2f2f2;
}

.Achievements_noAchievements__2fKMi {
    text-align: center;
    padding: 8px;
    margin-bottom: 10px;
}

.Achievements_title__GoG5R {
    font-weight: 600;
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.Achievements_actionButton__bFTYN {
    padding: 5px 10px;
    margin: 5px 0 5px 5px; 
    cursor: pointer;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
    white-space: nowrap; 
}

.Achievements_actionButton__bFTYN:hover {
    background-color: #1E7767;
}

.Achievements_actionButton__bFTYN:active {
    transform: scale(0.95);
}

.Achievements_styledInput__23oAP {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    margin-right: 10px; 

    
}

.Achievements_addAchievementArea__3mXn4 {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-top: 20px;
    width: 100%; 
}

.Achievements_addAchievementArea__3mXn4 .Achievements_styledInput__23oAP {
    flex-grow: 1;
}

.Achievements_achievementTitle__2b7Bt {
    flex: 5 1;
    font-size: 0.8em; 
    margin: 2px 0;
    font-weight: 800;
}

.Achievements_achievementDate__143rN {
    flex: 1 1;
    font-size: 0.8em; 
    margin: 2px 0; 
}

@media (max-width: 768px) {
    .Achievements_listItem__k9bCm {
        justify-content: flex-start; 
    }

    .Achievements_achievementTitle__2b7Bt,
    .Achievements_achievementDate__143rN {
        flex-basis: 100%; 
        margin: 5px 0; 
    }

    .Achievements_actionButton__bFTYN {
        padding: 5px; 
    }

    .Achievements_addAchievementArea__3mXn4 {
        flex-direction: column; 
    }

    .Achievements_addAchievementArea__3mXn4 .Achievements_styledInput__23oAP,
    .Achievements_addAchievementArea__3mXn4 .Achievements_actionButton__bFTYN {
        width: 100%; 
        margin-right: 0; 
        margin-bottom: 10px; 
    }
}

.Goals_container__2sPSH {
    width: 100%;
    background-color: #e6f7ff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.Goals_list__MRGl8 {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.Goals_listItem__PTtm7 {
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap on smaller screens */
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

.Goals_listItem__PTtm7:nth-child(odd) {
    background-color: #f2f2f2;
}

.Goals_noGoals__1irH5 {
    text-align: center;
    padding: 8px;
    margin-bottom: 10px;
}

.Goals_title__2qHgD {
    font-weight: 600;
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.Goals_actionButton__1mF01 {
    padding: 5px 10px;
    margin: 5px 0 5px 5px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
    white-space: nowrap;
}

.Goals_actionButton__1mF01:hover {
    background-color: #1E7767;
}

.Goals_actionButton__1mF01:active {
    transform: scale(0.95);
}

.Goals_styledInput__zEiC1 {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    margin-right: 10px;
    flex: 5 1;

}

.Goals_addGoalArea__1YV3H {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
}

.Goals_goalDescription__2igQZ {
    flex: 7 1;
    font-size: 0.8em;
    margin-left: 60px;
    font-weight: 800;
}

.Goals_goalDate__2j0i8 {
    flex: 1 1;
    font-size: 0.8em;
    margin: 2px 0;
}

.Goals_targetDate__PpuFK {
    flex: 1 1;
    font-size: 0.8em;
    margin: 2px 0;
}

.Goals_headings__2D3J8 {
    display: flex;
}

.Goals_addGoalHeadings__1GqOP {
    display: flex;
    border-top: 2px solid #ccc;
}

.Goals_checkbox__kYhVr {
    flex: 1 1;
    margin: 0px;
}

.Goals_achievedLabel__1eq4p {
    flex: 1 1;
}

.Goals_descriptionLabel__1jGad {
    flex: 6 1;
}

.Goals_dateLabel__26cJj {
    flex: 1 1;
}

.Goals_targetDateLabel__2AUUw {
    flex: 1 1;
}

.Goals_addDateLabel__3kQ7v {
    flex: 1 1;
    font-size: 0.7em;
    margin-top: 10px;
}

.Goals_addDescriptionLabel__2fw8H {
    flex: 5 1;
    font-size: 0.7em;
    margin-top: 10px;
}

.Goals_styledDateInput__oo-ii {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
    font-family: Lato, sans-serif;
    width: calc(100% - 22px); 
    flex: 1 1;
}

.Goals_styledDateInput__oo-ii:focus {
    border-color: #2A9D8F;
    outline: none;
    box-shadow: 0 0 0 2px rgba(42, 157, 143, 0.25);
}

.Goals_styledDateInput__oo-ii:disabled,
.Goals_styledDateInput__oo-ii[readonly] {
    background-color: #e6e6e6;
    cursor: not-allowed;
}

.Goals_addMobileDateLabel__WxDWY {
    display: none;
}

@media (max-width: 768px) {
    .Goals_listItem__PTtm7 {
        justify-content: flex-start;
    }

    .Goals_goalDescription__2igQZ,
    .Goals_goalDate__2j0i8 {
        flex-basis: 100%;
        margin: 5px 0;
    }

    .Goals_actionButton__1mF01 {
        padding: 5px;
    }

    .Goals_addGoalArea__1YV3H {
        flex-direction: column;
    }

    .Goals_addGoalArea__1YV3H .Goals_styledInput__zEiC1,
    .Goals_addGoalArea__1YV3H .Goals_actionButton__1mF01 {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .Goals_dateLabel__26cJj {
        display: none;
    }

    .Goals_descriptionLabel__1jGad {
        display: none;
    }

    .Goals_achievedLabel__1eq4p {
        display: none;
    }

    .Goals_addDateLabel__3kQ7v {
        display: none;
    }
    
    .Goals_addDescriptionLabel__2fw8H {
        display: none;
    }

    .Goals_targetDateLabel__2AUUw {
        display: none;
    }

    .Goals_goalDate__2j0i8 {
        display: none;
    }

    .Goals_addMobileDateLabel__WxDWY {
        display: block;
        margin-bottom: 5px;  
        font-size: 0.9em;
    }
}


.HealthTips_container__3ZjOM {
    width: 100%;
    background-color: #fff0e6;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.HealthTips_title__1fLFb {
    font-weight: 600;
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.HealthTips_list__Fn0Ku {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.HealthTips_listItem__FYrfh {

    padding: 8px;
}

.HealthTips_listItem__FYrfh:nth-child(odd) {
    background-color: #f2f2f2;
}

.HealthTips_advice__3HWFD {
    font-size: 0.8em;
}

.HealthTips_noTips__3-9_w {
    text-align: center;
    padding: 8px;
    margin-bottom: 10px;
}

.KeyMetrics_container__3HQMS {
    width: 100%;
    background-color: #e6ffe6;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.KeyMetrics_table__2EQzU {
    width: 100%;
}

.KeyMetrics_table__2EQzU th,
.KeyMetrics_table__2EQzU td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.KeyMetrics_table__2EQzU th {
    background-color: #f2f2f2;
}

.KeyMetrics_metricRow__S1vKr:hover {
    background-color: #f5f5f5;
}

.KeyMetrics_title__38kqF {
    font-weight: 600;
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.ReportResponse_container__33THk {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
}

.ReportResponse_title__1H6Af {
    text-align: center;
    margin-bottom: 20px;
    color: #2A9D8F;
}

.ReportResponse_header__3s9OL {
    font-size: 2rem;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.ReportResponse_summary__ClNBP {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
}

.ReportResponse_randomExplanation__2eU9W {
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.ReportResponse_adviceList__tB-he {
    list-style-type: disc;
    padding-left: 40px;
}

.ReportResponse_adviceItem__qrRDv {
    margin-bottom: 10px;
}

.ReportResponse_link__2Ud7g {
    font-size: 0.8rem;
    text-decoration: none;
    color: #666;
}

.ReportErrorResponse_container__Uiya8 {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
}

.ReportErrorResponse_title__2M0HC {
    text-align: center;
    margin-bottom: 20px;
}

.ReportErrorResponse_summary__1hjnK {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
}

.ReportErrorResponse_styledLink__1d0Vo {
    text-decoration: none;
    color: #2A9D8F;
    font-weight: 600;
}

.ReportErrorResponse_styledLink__1d0Vo:hover {
    text-decoration: underline;
}

.Disclaimer_container__3s_Zp {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px; 
        margin-top: 0px; 
    }
}

.Disclaimer_title__10Bok {
    text-align: center;
    margin-bottom: 20px;
}

.Disclaimer_sectionTitle__2RNVa {
    margin-top: 20px;
}

.Disclaimer_paragraph__5XXcR {
    margin-bottom: 15px;
    line-height: 1.5;
}


.LeaderBoard_container__FqRiO {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px; 
        margin-top: 0px; 
    }
}

.LeaderBoard_title__xjMoI {
    text-align: center;
    margin-bottom: 20px;
}

.LeaderBoard_table__1Pk3j {
    width: 100%;
    border-collapse: collapse;
}

.LeaderBoard_tableRow__2IH42 {
    @media (max-width: 768px) {
        display: none;
    }
}

.LeaderBoard_tableRow__2IH42:nth-child(even) {
        background-color: #f2f2f2;
    }

.LeaderBoard_tableHeader__wEp-T {
    border-bottom: 2px solid #ddd;
    padding: 12px 8px;
    text-align: left;
}

.LeaderBoard_tableCell__8rQrc {
    padding: 8px;
}

.LeaderBoard_paragraph__MCUaW {
    margin-bottom: 15px;
    line-height: 1.5;
}

.LeaderBoard_card__1YVAV {
    display: none;

    @media (max-width: 768px) {
        display: block;
        margin-bottom: 15px;
        border: 1px solid #e0e0e0;
        padding: 10px;
        border-radius: 5px;
    }
}

.LeaderBoard_cardItem__kL-Tb {
    margin-bottom: 5px;
}

.SiteMap_container__24VGT {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
}

.SiteMap_title__1HncL {
    text-align: center;
    margin-bottom: 20px;
}

.SiteMap_siteMapList__EGiTh {
    list-style-type: none;
    padding-left: 0;
}

.SiteMap_siteMapItem__1s8FB {
    margin-bottom: 10px;
}

.SiteMap_siteMapLink__USEJZ {
    text-decoration: none;
    color: blue;
}

.SiteMap_siteMapLink__USEJZ:hover {
    text-decoration: underline;
}

.SiteMap_siteMapItemDescription__1kbA3 {
    margin-bottom: 10px;
}



.MushroomStats_container__1VS8U {
    position: relative;
    padding: 20px;
    background-color: #B6B6B6;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 16px; 
    }
}

.MushroomStats_title__1FJkR {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.MushroomStats_mushroomSection__3fIfW {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        margin-bottom: 10px; 
    }
}

.MushroomStats_statBox__RRvx_ {
    position: absolute;
    padding: 5px;
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    z-index: 1;
}

.MushroomStats_topStat__1ZLc8 {
    top: -10px; 
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.7); 

    @media (max-width: 768px) {
        font-size: 14px;
        padding: 10px; 
    }
}

.MushroomStats_leftStat__37swE {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 768px) {
       margin-top: 15px;
    }
}

.MushroomStats_rightStat__uaF5U {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 768px) {
       margin-top: 15px;
    }
}

.MushroomStats_accessoriesSection__1CzYz {
    display: flex;
    flex-direction: column; 
    align-items: center;     
    grid-gap: 10px;     
    gap: 10px;
    padding: 10px; 
    background-color: #f4f4f4; 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.MushroomStats_accessoryBox__3N2d3 {
    border: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 150px;  
    text-align: center; 
}

.MushroomStats_itemTitle__2QwLT {
    font-weight: bold;
}

.MushroomStats_tooltip__2R0U2 {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 2;
    bottom: 125%; 
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.MushroomStats_tooltip__2R0U2::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}


.MushroomStats_tooltipContainer__2HaFj {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.MushroomStats_tooltipContainer__2HaFj:hover .MushroomStats_tooltip__2R0U2 {
    visibility: visible;
    opacity: 1;
}

.MushroomStats_skillsSection__1itlS {
    display: flex;
    flex-direction: column; 
    align-items: center;     
    grid-gap: 10px;     
    gap: 10px;
    padding: 10px; 
    background-color: #f4f4f4; 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.MushroomStats_skill__lCdzq {
    position: relative;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 5px;
    margin: 5px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.9);
}

.MushroomStats_updated__1mDh_ {
  margin-top: 20px;
  font-style: italic;
  font-size: 14px;
  color: #555;
}

/*const AccessoryImage = styled.img`
    position: absolute;
    max-width: 43%; 
    max-height: 43%; 
    z-index: 1;     
`;*/

.MushroomStats_section__xGYuQ {
  margin: 10px 0;
}

.MushroomStats_sectionHeader__3sE-W {
    background-color: #00796B;
    color: white;
    padding: 10px;
    cursor: pointer;
    width: 300px;
    border-radius: 10px 10px 0 0;
}

@media (max-width: 768px) {
    .MushroomStats_sectionHeader__3sE-W {
       width: 200px;
    }
    .MushroomStats_sectionHeader__3sE-W.MushroomStats_open__1xsNP {
       border-radius: 10px 10px 0 0;
    }
    .MushroomStats_sectionHeader__3sE-W.MushroomStats_closed__2ADS0 {
       border-radius: 10px;
    }
}


.MushroomStats_sectionContent__tF5tM {
    display: block;
}

@media (max-width: 768px) {
    .MushroomStats_sectionContent__tF5tM {
       display: none;
    }
    .MushroomStats_sectionContent__tF5tM.MushroomStats_open__1xsNP {
       display: block;
    }
}

.MushroomStats_imageWrapper__2P8l3 {
    margin-top: 40px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    transform: scale(0.85);
}

@media (max-width: 768px) {
    .MushroomStats_imageWrapper__2P8l3 {
        transform: scale(0.60);
        margin-top: 30px;
    }
}



.MushroomStats_smallImageWrapper__2O5jK {
    display: inline;
    margin-top: 0px;
}


.MushroomIcon_small__qYSlj {
    position: relative;
    max-width: 20px;
    max-height: 20px;
}

.MushroomIcon_default__1Cq4t {
    transform: scale(0.85);
    object-fit: contain;
}

.GameInstructions_container__2kyUk {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.GameInstructions_title__1WV4g {
    text-align: center;
    margin-bottom: 20px;
}

.GameInstructions_sectionTitle__ynN8b {
    margin-top: 20px;
}

.GameInstructions_paragraph__2-NJV {
    margin-bottom: 15px;
    line-height: 1.5;
}

.GameInstructions_link__1DoRj {
    text-decoration: none;
    color: #666;
}

.ReportInstructions_container__fwiM6 {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.ReportInstructions_title__GRMqw {
    text-align: center;
    margin-bottom: 20px;
}

.ReportInstructions_sectionTitle__UG9Gb {
    margin-top: 20px;
}

.ReportInstructions_paragraph__22WZm {
    margin-bottom: 15px;
    line-height: 1.5;
}

.ReportInstructions_startButton__srlyv {
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 22px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 auto;
}

.ReportInstructions_startButton__srlyv:hover {
    background-color: #1E7767;
}

.ReportInstructions_startButton__srlyv:active {
    transform: scale(0.95);
}

.ReportInfo_container__3rgIx {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.ReportInfo_title__3D4Ga {
    text-align: center;
    margin-bottom: 20px;
}

.ReportInfo_sectionTitle__2HpWt {
    margin-top: 20px;
}

.ReportInfo_paragraph__1n1Ny {
    margin-bottom: 15px;
    line-height: 1.5;
}

.ReportInfo_startButton__1hpDa {
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 22px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 auto;
}

.ReportInfo_startButton__1hpDa:hover {
    background-color: #1E7767;
}

.ReportInfo_startButton__1hpDa:active {
    transform: scale(0.95);
}

.Videos_title__cCZwc {
    margin-left: 10px;
}

.ProductList_container__1Uk9M {
    max-width: 800px;
    margin: 40px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    box-sizing: border-box;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        grid-template-columns: 1fr; 
    }
}

.ProductList_title__J-jXd {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.ProductList_styledLink__ywLbp {
    text-decoration: none;
    color: inherit;
}

.ProductList_productContainer__3J5CC {
    width: 100%;
    min-height: 350px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: background-color 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
}

.ProductList_productContainer__3J5CC h3,
.ProductList_productContainer__3J5CC p {
    margin: 10px 0;
}

.ProductList_productContainer__3J5CC:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

.ProductList_productImage__3geTs {
    max-height: 120px;
    width: auto;
    margin-bottom: 15px;
    object-fit: contain;
    align-self: center;
  }  

.ProductList_productName__l03dW {
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 10px;
}

.ProductList_productDescription__2H2hU {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 10px;
}

.ProductList_productPrice__5z6b8 {
    font-size: 1.1em;
    color: #00796B;
    font-weight: bold;
}

.CategoryList_container__sLh9q {
    max-width: 800px;
    margin: 40px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    box-sizing: border-box;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.CategoryList_title__2mUeF {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.CategoryList_categoryContainer__s_qqW {
    width: 100%;
    min-height: 350px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: background-color 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    
  }
  
  .CategoryList_categoryContainer__s_qqW h3,
  .CategoryList_categoryContainer__s_qqW p {
    margin: 10px 0;
  }
  
  .CategoryList_categoryContainer__s_qqW:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

.CategoryList_styledLink__21_Xo {
    text-decoration: none;
    color: inherit;
}

.CategoryList_categoryImage__2mlnd {
    max-height: 120px;
    width: auto;
    margin-bottom: 15px;
    object-fit: contain;
    align-self: center;
}

.CategoryList_categoryName__1p9eF {
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 10px;
}

.CategoryList_categoryDescription__3VE3q {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 10px;
}

.CategoryList_introParagraph__1pIan {
    text-align: center;
    font-size: 18px;
    max-width: 800px;
    margin: 0 auto 40px;
    line-height: 1.6;
    color: #333;
    font-family: Lato, sans-serif;
}

.Product_container___Jwlg {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
}

.Product_title__1tnSu {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.Product_productImage__FWNcc {
    width: 20%;
    height: 20%;
    margin-bottom: 20px;
}

.Product_styledButton__mTuw3 {
    margin: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.Product_styledButton__mTuw3:hover {
    background-color: #1E7767;
}

.Product_optionButton__3t4HR {
    margin: 5px;
    padding: 10px;
    background-color: #ECECEC;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.Product_optionButton__3t4HR:hover {
    background-color: #DDDDDD;
}

.Product_optionButton__3t4HR.Product_selected__tT5Kh {
    background-color: #2A9D8F;
    color: white;
}

.Product_colourSwatch__1V78E {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #000;
    transition: border 0.3s, box-shadow 0.3s;
}

.Product_colourSwatch__1V78E.Product_selected__tT5Kh {
    border-color: #ffffff;
    box-shadow: 0 0 12px rgba(42, 157, 143, 0.6);
}

.Product_colourSwatch__1V78E:hover {
    box-shadow: 0 0 12px rgba(42, 157, 143, 0.6);
}

.Product_mandatoryNote__xkqBv {
    bottom: 10px;
    right: 10px;
    font-size: 0.8rem;
    color: red;
    text-align: right;
}

.Product_styledInput__2fcUq {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 25%;
    margin-left: 5px;

    @media (min-width: 769px) {
        width: auto;
    }
}

.Product_tooltip__3LdT5 {
    position: relative;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
}

.Product_tooltip__3LdT5 .Product_tooltiptext__nqI7R {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.Product_tooltip__3LdT5:hover .Product_tooltiptext__nqI7R {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.1s;
}

.Product_quantityContainer__3L9Et {
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 10px;
}

.Product_quantityStepper__1nyPB {
    display: flex;
    border: 1px solid #ccc;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-left: 10px;
    margin-top: 20px;
}

.Product_quantityStepper__1nyPB button {
    padding: 5px 15px;
    cursor: pointer;
    border: none;
    background-color: #ECECEC;
    transition: background-color 0.3s, box-shadow 0.3s;
    border-radius: 5px;
}

.Product_quantityStepper__1nyPB button:hover {
    background-color: #DDDDDD;
}

.Product_quantityStepper__1nyPB button:active {
    background-color: #BFBFBF;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.Product_quantityStepper__1nyPB button:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(42, 157, 143, 0.6);
}

.Product_quantityStepper__1nyPB input {
    width: 50px;
    text-align: center;
    border: none;
    font-size: 0.9em;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

/* removing spinner arrows for Firefox */
.Product_quantityStepper__1nyPB input::-webkit-outer-spin-button,
.Product_quantityStepper__1nyPB input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.Product_description__30rP1 {
    font-size: 0.8em;
}

.Product_price__17JLl {
    font-size: 0.8em;
}

.Product_label__1C3V2 {
    font-size: 0.9em;
    font-weight: 800;
}

.Product_colourLabel__10REl {
    vertical-align: 50%;
    font-size: 0.9em;
    font-weight: 800;
}

*, *:before, *:after {
    box-sizing: border-box;
}

.Basket_container__2jdcQ {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: Lato, sans-serif;
}

.Basket_title__3bkF0 {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.Basket_emptyBasketMessage__3iKH4 {
    color: #757575;
    font-style: italic;
    margin: 20px 0;
}

.Basket_checkoutButton__w-tlN,
.Basket_removeButton__2ysNz {
    padding: 10px 20px;
    margin: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.Basket_checkoutButton__w-tlN {
    width: auto;
    margin: 1rem auto; 
    font-size: 1.1em;
}

.Basket_checkoutButton__w-tlN:hover,
.Basket_removeButton__2ysNz:hover {
    background-color: #1E7767;
}

.Basket_basketSummary__1-FC3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

.Basket_totalItems__2DHyW {
    font-weight: bold;
}

.Basket_totalPrice__1vn5M {
    color: #00796B;
    font-weight: bold;
    font-size: 1.2em;
}

.Basket_paypalButtonContainer__33A-1 {
    width: auto;
    margin: 1rem auto;
}

.Basket_paymentNote__3QSvV {
    margin-top: 20px;
    color: #757575;
    font-style: italic;
    font-size: 0.9em;
    text-align: center;
}

.Basket_basketHeader__3dNFj,
.Basket_basketItem__ep8qX {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
}

/*.basketItem {
    flex-direction: row;
}*/

.Basket_label__68Kwx, 
.Basket_itemDetail__2Btd7 {
    flex: 1 1;
    text-align: center;
    padding: 0.5em;
    margin: 0;
}

.Basket_itemDetail__2Btd7 {
    font-size: 0.9em;
}

.Basket_headerName__Weba0, 
.Basket_headerBasketKey__3rRD9, 
.Basket_headerUnitPrice__12fku, 
.Basket_headerSize__3BmcJ, 
.Basket_headerColour__3YOTc, 
.Basket_headerQuantity__oYN-c, 
.Basket_headerTotalPrice__2Utgv,
.Basket_itemName__3hQez,
.Basket_itemBasketKey__i9xYJ,
.Basket_itemUnitPrice__KzcuQ,
.Basket_itemSize__1rDhN,
.Basket_itemColour__pgYZ3,
.Basket_itemQuantity__-Ax-5,
.Basket_itemTotalPrice__1quIJ {
    flex: 1 1;
    text-align: center;
    padding: 0.5em;
}

.Basket_removeButton__2ysNz {
    flex-basis: auto;
    width: 100px;
    margin: 0 auto;
}

.Basket_headerRemove__1S_D6 {
    flex-basis: 100px;
    padding: 0.5em;
    text-align: center;
}



.Basket_inlineLabel__GF-Ic {
    display: none; /* Hide inline labels on desktop */
}


@media (max-width: 767px) { /* Adjust breakpoint as needed */

    .Basket_basketHeader__3dNFj {
        display: none; /* Hide the header on mobile */
    }

    .Basket_inlineLabel__GF-Ic {
        display: inline-block; /* Show inline labels on mobile */
        
    }

    /* Make text slightly larger for better readability */
    .Basket_itemName__3hQez, .Basket_itemPrice__3En95 {
        font-size: 18px;
    }

    /* Increase button sizes for better tap targets */
    .Basket_checkoutButton__w-tlN, .Basket_removeButton__2ysNz {
        padding: 12px 24px;
        font-size: 20px;
        width: 100%;
        margin: 10px 0;
    }

    /* Stack basketHeader and basketItem vertically */
    .Basket_basketItem__ep8qX {
        flex-direction: column;
    }

    .Basket_basketItem__ep8qX > div {
        /* Full width, smaller padding, and centered text */
        width: 100%;
        padding: 0.25em;
        text-align: center;
    }

    /* Adjust total summary for mobile */
    .Basket_basketSummary__1-FC3 {
        flex-direction: column;
        align-items: center;
    }

    .Basket_totalItems__2DHyW, .Basket_totalPrice__1vn5M {
        margin: 5px 0;
    }

    /* Adjusting other elements for better spacing */
    .Basket_paymentNote__3QSvV, .Basket_paypalButtonContainer__33A-1 {
        margin-top: 15px;
    }

    .Basket_basketItem__ep8qX {
        flex-direction: column;
        border: 1px solid #eee;
        margin: 10px 0;
        padding: 10px;

    }

    .Basket_basketItem__ep8qX > div {
        margin-bottom: 5px;
    }

    .Basket_basketItem__ep8qX > div strong {
        margin-right: 5px;
    }

    .Basket_removeButton__2ysNz {
        flex-basis: auto;
        width: 50%;
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .Basket_itemName__3hQez,
    .Basket_itemBasketKey__i9xYJ,
    .Basket_itemUnitPrice__KzcuQ,
    .Basket_itemSize__1rDhN,
    .Basket_itemColour__pgYZ3,
    .Basket_itemQuantity__-Ax-5,
    .Basket_itemTotalPrice__1quIJ {
        flex-direction: row;
        align-items: left;
    }
}


.CheckoutSuccess_container__2tlMa {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    text-align: center;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.CheckoutSuccess_title__36-TH {
    font-size: 2rem;
    color: #00796B;
    margin-bottom: 20px;
}

.CheckoutSuccess_subtitle__2VXH2 {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 20px;
}

.CheckoutSuccess_text__3Gbjq {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.CheckoutSuccess_button__17ITy {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.CheckoutSuccess_button__17ITy:hover {
    background-color: #1E7767;
}

.CheckoutFailure_container__35q7a {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    text-align: center;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.CheckoutFailure_title__1k2x0 {
    font-size: 2rem;
    color: #D32F2F;
    margin-bottom: 20px;
}

.CheckoutFailure_subtitle__2F2Dm {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 20px;
}

.CheckoutFailure_text__RsIFz {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.CheckoutFailure_button__751FN {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.CheckoutFailure_button__751FN:hover {
    background-color: #1E7767;
}

.CheckoutCancel_container__3Fm4- {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    text-align: center;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.CheckoutCancel_title__2_VRx {
    font-size: 2rem;
    color: #D32F2F;
    margin-bottom: 20px;
}

.CheckoutCancel_subtitle__CGZDu {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 20px;
}

.CheckoutCancel_text__1GIKf {
    font-size: 1rem;
    color: #555;
    margin-bottom: 20px;
}

.CheckoutCancel_button__2yvs_ {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.CheckoutCancel_button__2yvs_:hover {
    background-color: #1E7767;
}

.Checkout_container__2Talw {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Lato, sans-serif;
}

.Checkout_title__3eBfB {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.Checkout_subTitle__3ytSb {
    font-weight: 600;
    font-size: 24px;
    color: #2A9D8F;
}

.Checkout_form__18F2H {
    width: 80%;
}

.Checkout_formGroup__bRnJA {
    margin-bottom: 15px;
}

.Checkout_label__3fvJY {
    display: block;
    margin-bottom: 5px;
}

.Checkout_input__35dVX {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.Checkout_submitButton__1TsmN {
    padding: 10px 20px;
    background-color: #2A9D8F;
    font-size: 18px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 20px;
}

.Checkout_submitButton__1TsmN:hover {
    background-color: #1E7767;
}

.Checkout_paypalButtonContainer__1CmNw {
    width: auto;
    margin: 1rem auto;
}

.Checkout_paymentNote__1pr5s {
    margin-top: 20px;
    color: #757575;
    font-style: italic;
    font-size: 0.9em;
    text-align: center;
}

.Checkout_styledInput__3ZYzh {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;

    @media (min-width: 769px) {
        width: auto;
    }
}

.Checkout_styledLabel__3QXmz {
    font-weight: bold;
    width: 190px;
    text-align: right;
    margin-right: 10px;

    @media (max-width: 768px) {
        text-align: left;
    }
}

.Checkout_mandatoryMarker__2FqRv {
    color: red;
}

.Checkout_inputGroup__3AaZ_ {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }

}

.Checkout_validationBlock__1Ueai {
    color: red;
    font-size: 0.8em;
    margin-left: 5px;
}

.Checkout_mandatoryNote__1PnRG {
    font-size: 0.9rem;
    text-decoration: none;
    color: #666;
    margin-top: 10px;
}



.HelloTensor_container__1ELxu {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
}

.CookieBanner_container__2rUEr {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #555555; /* Dark background for contrast */
  color: #ffffff;
  padding: 12px 20px; /* Reduced padding */
  text-align: center;
  z-index: 1000;
  font-family: 'Lato', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.CookieBanner_text__QCn17 {
  margin-bottom: 8px; /* Slightly reduced margin */
  padding-right: 5px;
}

.CookieBanner_button__1YMnC {
  padding: 8px 20px; /* Adjust padding */
  background-color: #5bc0de; /* Teal color for the button */
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.CookieBanner_button__1YMnC:hover {
  background-color: #555; /* Darken button on hover for desktop */
}

@media (max-width: 768px) {
  .CookieBanner_container__2rUEr {
    padding: 10px; /* Even smaller padding on mobile */
    flex-direction: column; /* Stack elements vertically */
  }

  .CookieBanner_text__QCn17 {
    text-align: center; /* Ensure text is centered */
    margin-bottom: 5px; /* Smaller margin */
    
  }

  .CookieBanner_button__1YMnC {
    width: 100%; /* Full-width button for easier tapping */
    margin-top: 10px;
    margin-left: 0;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em; /* Ensure readable font size */
  }
}


.AdminTools_container__KVv_g {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border: 1px solid #eee;
    margin: 20px auto;
    font-family: Lato, sans-serif;
}

.AdminTools_title__170LI {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.AdminTools_toolAction__2i9SH {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.AdminTools_actionButton__3jhyv {
    padding: 8px 15px;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.AdminTools_note__1jfOw {
    font-size: 12px;
    color: #555;
}

.AdminTools_result__OPQD_ {
    padding: 10px;
    border: 1px solid #aaa;
    width: 100%;
    overflow: auto;
    margin-top: 20px;
}


.ChatBot_container__3pVSb {
    position: relative;
    width: 300px;
    display: inline-block;
    font-family: Lato, sans-serif;
}

.ChatBot_chatBotWindow__3cd8X {
    width: 100%;
    height: 400px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #e6f7ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    position: absolute;
    bottom: 40px;
    display: none;
}

.ChatBot_open__1d6uG {
    display: block;
}

.ChatBot_chatbotLink__1_Fs2 {
    font-family: Lato, sans-serif;
    padding: 5px 5px;
    font-size: 16px;
    background-color: #E9F7EF;
    color: #333333;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
}

.ChatBotContent_container__31tH0 {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-family: Lato, sans-serif;
}

.ChatBotContent_messageList__1k0C8 {
    flex: 1 1;
    overflow-y: auto;
}


.ChatBotContent_message__12Slo {
    padding: 8px;
    padding-bottom: 0px;
    margin: 5px;
    font-size: 0.8rem;
    border-radius: 5px;
}

.ChatBotContent_bot__2ztFQ {
    background-color: #ccc;
    color: black;
    align-self: flex-start;
}

.ChatBotContent_user__10jI4 {
    background-color: #2A9D8F;
    color: #1E7767;
    align-self: flex-end;
}

.ChatBotContent_inputContainer__2JsGb {
    display: flex;
    margin-top: 10px;
}


.ChatBotContent_chatInput__3x0MW {
    flex: 1 1;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
}


.ChatBotContent_sendButton__3gS8R {
    padding: 8px 15px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.ChatBotContent_sendButton__3gS8R:hover {
    background-color: #1E7767;
}


.TheMushroom_container__3Wtur {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px; 
        margin-top: 0px; 
    }
}

.TheMushroom_title__2oiQZ {
    text-align: center;
    margin-bottom: 20px;
}

.TheMushroom_sectionTitle__30fy- {
    margin-top: 20px;
}

.TheMushroom_paragraph__2j5nv {
    margin-bottom: 15px;
    line-height: 1.5;
}

.TheMushroom_link__30hpd {
    text-decoration: none;
    color: #666;
}


.VoteSnackbarContent_content__2rCdj {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: #323232;
    color: white;
    border-radius: 4px;
}

.VoteSnackbarContent_message__1_BTc {
    flex: 1 1;
}

.VoteSnackbarContent_styledButton__adxmK {
    margin-left: 8px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

.Sudoku_container__kqkPj {
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #fff0e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
    width: -webkit-max-content;
    width: max-content;
  }
  
  .Sudoku_title__3obF8 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .Sudoku_description__GsG44 {
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .Sudoku_board__37eu1 {
    display: grid;
    grid-template-columns: repeat(9, 40px);
    grid-gap: 5px;
  }
  
  .Sudoku_row__1Ojaf {
    display: contents;
  }
  
  .Sudoku_cell__3YJbK {
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0;
  }
  
  .Sudoku_cell__3YJbK:nth-child(3n) {
    border-right: 2px solid #888;
  }
  
  .Sudoku_cell__3YJbK:nth-child(3n + 1) {
    border-left: 2px solid #888;
  }
  
  .Sudoku_cell__3YJbK:nth-child(-n + 27), .Sudoku_cell__3YJbK:nth-child(n + 27):nth-child(-n + 54) {
    border-bottom: 2px solid #888;
  }
  
  .Sudoku_cell__3YJbK:nth-child(n + 54):nth-child(-n + 81) {
    border-top: 2px solid #888;
  }
  
.NewsLetterSignup_container__17jZT {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.NewsLetterSignup_title__3FvM0 {
    text-align: center;
    margin-bottom: 20px;
}

.NewsLetterSignup_paragraph__3g3Hq {
    margin-bottom: 15px;
    line-height: 1.5;
}

.NewsLetterSignup_paragraph__3g3Hq strong {
    font-weight: bold;
}

.NewsLetterSignup_paragraph__3g3Hq ul {
    margin-left: 20px;
    list-style-type: disc;
}

.NewsLetterSignup_paragraph__3g3Hq li {
    margin-bottom: 10px;
}

.NewsLetterSignup_input__1QYpp {
    width: 70%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.NewsLetterSignup_button__12ole {
    padding: 10px 15px;
    background-color: #2A9D8F;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.NewsLetterSignup_button__12ole:hover {
    background-color: #1E7767;
}

.NewsLetterSignup_button__12ole:active {
    transform: scale(0.95);
}

.NewsLetterSignup2_container__3I411 {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.NewsLetterSignup2_title__2eTTS {
    text-align: center;
    margin-bottom: 20px;
}

.NewsLetterSignup2_paragraph__Rr3o_ {
    margin-bottom: 15px;
    line-height: 1.5;
}

.NewsLetterSignup2_paragraph__Rr3o_ strong {
    font-weight: bold;
}

.NewsLetterSignup2_paragraph__Rr3o_ ul {
    margin-left: 20px;
    list-style-type: disc;
}

.NewsLetterSignup2_paragraph__Rr3o_ li {
    margin-bottom: 10px;
}

.NewsLetterSignup2_input__2cL1_ {
    width: 70%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.NewsLetterSignup2_button__1fbvg {
    padding: 10px 15px;
    background-color: #2A9D8F;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.NewsLetterSignup2_button__1fbvg:hover {
    background-color: #1E7767;
}

.NewsLetterSignup2_button__1fbvg:active {
    transform: scale(0.95);
}

.NewsLetterSignupModal_overlay__3f9IX {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.NewsLetterSignupModal_container__1Rzb- {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
}

.NewsLetterSignupModal_title__26oqC {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.NewsLetterSignupModal_paragraph__NZKpN {
    font-size: 1em;
    margin-bottom: 20px;
}

.NewsLetterSignupModal_formContainer__1ftwG {
    display: flex;
    flex-direction: column;
}

.NewsLetterSignupModal_input__JjgB7 {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.NewsLetterSignupModal_button__Hf8WZ, .NewsLetterSignupModal_secondaryButton__e9sGt {
    padding: 10px 20px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
}

.NewsLetterSignupModal_button__Hf8WZ {
    background-color: #007bff;
    color: white;
}

.NewsLetterSignupModal_secondaryButton__e9sGt {
    background-color: #6c757d;
    color: white;
}

.NewsLetterSignupModal_errorMessage__3l_hi {
    color: red;
    font-size: 0.8em;
}

.AIEmbrace_container__1gUi4 {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px; 
        margin-top: 0px; 
    }
}

.AIEmbrace_title__10ri8 {
    text-align: center;
    margin-bottom: 20px;
}

.AIEmbrace_sectionTitle__ZwxiW {
    margin-top: 20px;
}

.AIEmbrace_paragraph__1TrKz {
    margin-bottom: 15px;
    line-height: 1.5;
}



.DonationsPage_container__37ALw {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px; 
        margin-top: 0px; 
    }
}

.DonationsPage_title__3x6Aj {
    text-align: center;
    margin-bottom: 20px;
}

.DonationsPage_paragraph__lbegS {
    margin-bottom: 15px;
    line-height: 1.5;
}

.DonationsPage_donateButton__SzFcQ {
    display: block;
    margin: 20px auto;
}

.DonationsPage_bitcoinQR__1y_e2 {
    max-width: 300px;
    margin: 0 auto;
    display: block;
}

.DonationsPage_bitcoinContainer__3mcs3 {
    text-align: center;
    margin-bottom: 20px;
}

.CollectibleMushrooms_container__2fozO {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px; 
        margin-top: 0px; 
    }
}

.CollectibleMushrooms_title__jShnG {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.CollectibleMushrooms_title__jShnG strong {
    font-weight: 800;
}

.CollectibleMushrooms_description__torr_ {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.5;
}

.CollectibleMushrooms_description__torr_ strong {
    font-weight: bold;
}

.CollectibleMushrooms_feedback__3-0LO {
    width: 35%;
    margin: 0 auto;
}

.CollectibleMushrooms_section__3Mt7o {
    margin-bottom: 50px;
}

.CollectibleMushrooms_highlight__38Tlt {
    font-weight: bold;
    color: #1E7767;
}

.CollectibleMushrooms_signupButton__1XeFK {
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 22px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 auto;
}

.CollectibleMushrooms_signupButton__1XeFK:hover {
    background-color: #1E7767;
}

.CollectibleMushrooms_signupButton__1XeFK:active {
    transform: scale(0.95);
}

.Collectibles_container__3OsJ7 {
    padding: 20px;
    font-family: Lato, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    font-family: Lato, sans-serif;
}

.Collectibles_collectiblesButton__2hQkA {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.Collectibles_collectiblesButton__2hQkA:hover {
    background-color: #1E7767;
}

.Collectibles_collectiblesButton__2hQkA:active {
    transform: scale(0.95);
}

.Collectibles_collectiblesButton__2hQkA:disabled {
    cursor: not-allowed;
}

.Collectibles_overlay__9WpfK {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.Collectibles_modalContainer__2D8kX {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.Collectibles_button__3w-XM {
    padding: 10px 15px;
    background-color: #2A9D8F;
    font-size: 18px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.Collectibles_button__3w-XM:hover {
    background-color: #1E7767;
}

.Collectibles_title__1eqvG {
    color: #2A9D8F;
}

.Collectibles_tooltip__bOMVt {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 2;
    bottom: 125%; 
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.Collectibles_tooltip__bOMVt::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}


.Collectibles_tooltipContainer__1iONu {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.Collectibles_tooltipContainer__1iONu:hover .Collectibles_tooltip__bOMVt {
    visibility: visible;
    opacity: 1;
}

.Books_booksContainer__wh7Zy {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    padding: 40px 10px;
    font-family: 'Lato', sans-serif;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}

.Books_title__1qZWi {
    font-weight: 600;
    font-size: 24px;
    color: #2A9D8F;
    text-align: center;
}

.Books_booksGrid__3SCli {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Automatically fits 2-4 books per row */
    grid-gap: 20px;
    gap: 20px;
    justify-items: center;
    width: 100%;
    max-width: 900px;
}

.Books_bookCard__2KRSG {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #f5f5f5;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: 100%;
    max-width: 250px; /* Keeps book cards at a reasonable size */
}

.Books_bookCard__2KRSG:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.Books_bookImage__W-XTP {
    width: 150px; /* Ensures consistent width */
    height: 200px; /* Forces all images to be the same height */
    object-fit: cover; /* Ensures images crop instead of stretching */
    border-radius: 5px;
    margin-bottom: 10px;
}


/* Responsive layout for smaller screens */
@media (max-width: 768px) {
    .Books_booksGrid__3SCli {
        grid-template-columns: repeat(2, 1fr); /* 2 books per row on tablets */
    }
}

@media (max-width: 480px) {
    .Books_booksGrid__3SCli {
        grid-template-columns: 1fr; /* Stacks to 1 per row on mobile */
    }

    .Books_bookImage__W-XTP {
        width: 100px; /* Smaller images for mobile */
    }
}

.Books_bookTitle__32Da1 {
    font-weight: bold;
    font-size: 18px;
    color: #2A9D8F;
    margin-bottom: 10px;
}

.Books_bookDescription__3RXxN {
    text-align: left;
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
    padding: 0 10px;
}

.Books_bookLink__3iym1 {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
}

.Books_bookLink__3iym1:hover {
    background-color: #1E7767;
}

.Books_bookLink__3iym1:active {
    transform: scale(0.95);
}

.PuzzleBookSolutionSignup_container__UZmTg {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.PuzzleBookSolutionSignup_title__dWIXH {
    text-align: center;
    margin-bottom: 20px;
}

.PuzzleBookSolutionSignup_paragraph__19zqW {
    margin-bottom: 15px;
    line-height: 1.5;
}

.PuzzleBookSolutionSignup_paragraph__19zqW strong {
    font-weight: bold;
}

.PuzzleBookSolutionSignup_paragraph__19zqW ul {
    margin-left: 20px;
    list-style-type: disc;
}

.PuzzleBookSolutionSignup_paragraph__19zqW li {
    margin-bottom: 10px;
}

.PuzzleBookSolutionSignup_input__3RmeC {
    width: 70%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.PuzzleBookSolutionSignup_button__2bEnT {
    padding: 10px 15px;
    background-color: #2A9D8F;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.PuzzleBookSolutionSignup_button__2bEnT:hover {
    background-color: #1E7767;
}

.PuzzleBookSolutionSignup_button__2bEnT:active {
    transform: scale(0.95);
}

.AdminListOrders_container__Ob79W {
    overflow-x: auto;
    margin: 10px;
}

.AdminListOrders_title__UlqR2 {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.AdminListOrders_table__2JK3z {
    width: 100%;
    border-collapse: collapse;
}

.AdminListOrders_table__2JK3z th, .AdminListOrders_table__2JK3z td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
    font-size: 0.7em;
}

.AdminListOrders_table__2JK3z th {
    background-color: #f4f4f4;
}

@media screen and (max-width: 768px) {
    .AdminListOrders_table__2JK3z {
        display: block;
    }
}

.AdminListOrders_uuid__31AyK {
    font-size: 0.8em;
}

