.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.button {
  padding: 5px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #2A9D8F;
  color: white;
}

.button:hover {
  background-color: #1E7767;
}

.button:active {
  transform: scale(0.95);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}

.closeButton:hover {
  color: #888;
}
