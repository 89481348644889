.counterContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    background-color: #e6f7ff;
    flex: 1; 
    min-width: 150px; 
    text-align: center; 
}

.reportCount {
    font-size: 36px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.description {
    font-size: 18px;
    color: #777;
}
