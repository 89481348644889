.container {
    max-width: 800px;
    margin: 40px auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    box-sizing: border-box;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.categoryContainer {
    width: 100%;
    min-height: 350px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: background-color 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    
  }
  
  .categoryContainer h3,
  .categoryContainer p {
    margin: 10px 0;
  }
  
  .categoryContainer:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }

.styledLink {
    text-decoration: none;
    color: inherit;
}

.categoryImage {
    max-height: 120px;
    width: auto;
    margin-bottom: 15px;
    object-fit: contain;
    align-self: center;
}

.categoryName {
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 10px;
}

.categoryDescription {
    font-size: 0.9em;
    color: #555;
    margin-bottom: 10px;
}

.introParagraph {
    text-align: center;
    font-size: 18px;
    max-width: 800px;
    margin: 0 auto 40px;
    line-height: 1.6;
    color: #333;
    font-family: Lato, sans-serif;
}
