.container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px; 
        margin-top: 0px; 
    }
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.title strong {
    font-weight: 800;
}

.description {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.5;
}

.description strong {
    font-weight: bold;
}

.feedback {
    width: 35%;
    margin: 0 auto;
}

.section {
    margin-bottom: 50px;
}

.highlight {
    font-weight: bold;
    color: #1E7767;
}

.signupButton {
    display: block;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 22px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0 auto;
}

.signupButton:hover {
    background-color: #1E7767;
}

.signupButton:active {
    transform: scale(0.95);
}
