.container {
    width: 100%;
    background-color: #e6ffe6;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.table {
    width: 100%;
}

.table th,
.table td {
    text-align: left;
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

.table th {
    background-color: #f2f2f2;
}

.metricRow:hover {
    background-color: #f5f5f5;
}

.title {
    font-weight: 600;
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}
