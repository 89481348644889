.container {
    padding: 20px;
    font-family: Lato, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    font-family: Lato, sans-serif;
}

.collectiblesButton {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.collectiblesButton:hover {
    background-color: #1E7767;
}

.collectiblesButton:active {
    transform: scale(0.95);
}

.collectiblesButton:disabled {
    cursor: not-allowed;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContainer {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.button {
    padding: 10px 15px;
    background-color: #2A9D8F;
    font-size: 18px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.button:hover {
    background-color: #1E7767;
}

.title {
    color: #2A9D8F;
}

.tooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 2;
    bottom: 125%; 
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}


.tooltipContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.tooltipContainer:hover .tooltip {
    visibility: visible;
    opacity: 1;
}
