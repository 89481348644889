.small {
    position: relative;
    max-width: 20px;
    max-height: 20px;
}

.default {
    transform: scale(0.85);
    object-fit: contain;
}
