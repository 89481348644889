.container {
  width: 100%;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  font-family: Lato, sans-serif;
}

.title {
  font-weight: 600;
  font-size: 1.2em;
  color: #2A9D8F;
  margin-bottom: 20px;
  text-align: center;
}

.customLegend {
  list-style: none;
  display: flex;
}

.customLegend li {
  display: flex;
  align-items: center;
  margin-right: 16px;
}

.legendColourBox {
  width: 14px;
  height: 14px;
  display: block;
  margin-right: 4px;
}

/* Add a media query for mobile devices */
@media (max-width: 768px) { /* or another breakpoint that suits your design */
  .container {
    padding: 10px; /* smaller padding on mobile */
  }

  .title {
    font-size: 1em; /* smaller font size on mobile */
    margin-bottom: 10px;
  }

  /* Adjust the legend styling for smaller screens */
  .customLegend {
    flex-wrap: wrap; /* wrap legends if space is not sufficient */
    justify-content: center; /* center align the legend items */
  }

  .customLegend li {
    margin-right: 8px; /* smaller margin between legend items */
    margin-bottom: 5px; /* space between wrapped items */
  }

  .legendColourBox {
    width: 10px;
    height: 10px;
  }
}
