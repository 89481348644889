.container {
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #fff0e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.title {
    font-size: 24px;
    margin-bottom: 10px;
}

.description {
    font-size: 14px;
    margin-bottom: 20px;
}

.questionText {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.answerButton {
    margin: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.answerButton:hover {
    background-color: #1E7767;
}

.correctAnswer {
    font-size: 16px;
    color: green;
    font-weight: bold;
}
