.container {
  padding: 20px;
  border: 2px solid #ccc;
  border-radius: 10px;
  background-color: #e6f7ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  margin-bottom: 20px;
  font-family: Lato, sans-serif;
}

.tipHeader h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.tipText {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.tipButton {
  margin: 10px;
  padding: 10px 20px;
  font-size: 18px;
  background-color: #2A9D8F;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tipButton:hover {
  background-color: #1E7767;
}


.description {
  font-size: 14px;
  margin-bottom: 20px;
}


.answer {
  font-size: 16px;
  color: green;
  font-weight: bold;
}
