.content {
  display: flex;
  flex-wrap: nowrap;
  font-family: Lato, sans-serif;
}

@media (max-width: 1000px) {
  .content {
    padding: 0;
    flex-direction: column;
  }
}

.title {
  font-weight: 600;
  font-size: 32px;
  color: #2A9D8F;
  margin-bottom: 20px;
}

.title strong {
  font-weight: 800;
}

.introContainer {
  flex: 2;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  order: 1;
  font-family: Lato, sans-serif;

  @media (max-width: 1000px) {
    flex: 0 0 100%;
    padding-bottom: 0px;
  }

}

.centered {
  margin: auto 0;
  flex: 0 0 100%;
}

.introText {
  margin-bottom: 25px;
  font-size: 18px;

  @media(max-width: 768px) {
    margin-bottom: 10px;
    margin-top: 0px;
  }

}

.startButton {
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px 20px;
  font-size: 22px;
  background-color: #2A9D8F;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: Lato, sans-serif;
}

.startButton:hover {
  background-color: #1E7767;
}

.startButton:active {
  transform: scale(0.95);
}

.sidebar {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
  order: 2;

  @media (max-width: 1000px) {
    flex: 0 0 100%;
  }
}

.styledLink {
  text-decoration: none;
  color: #2A9D8F;
  font-weight: 600;
  transition: all 0.3s ease;
}

.styledLink:hover {
  text-decoration: underline;
}

.centeredContainer {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
