.container {
  width: calc(100% - 20px);  
  height: 0;
  padding: 10px;  
  padding-bottom: calc(56.25% - 20px);  /* 16:9 Aspect Ratio, adjusted for 20px vertical padding */
  position: relative;
}

.videoFrame {
  position: absolute;
  top: 10px;  
  left: 10px; 
  width: calc(100% - 20px);  /* Adjusted for side padding */
  height: calc(100% - 20px);  /* Adjusted for top and bottom padding */
  border: 0;
}
