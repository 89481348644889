.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #555555; /* Dark background for contrast */
  color: #ffffff;
  padding: 12px 20px; /* Reduced padding */
  text-align: center;
  z-index: 1000;
  font-family: 'Lato', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.text {
  margin-bottom: 8px; /* Slightly reduced margin */
  padding-right: 5px;
}

.button {
  padding: 8px 20px; /* Adjust padding */
  background-color: #5bc0de; /* Teal color for the button */
  color: #ffffff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #555; /* Darken button on hover for desktop */
}

@media (max-width: 768px) {
  .container {
    padding: 10px; /* Even smaller padding on mobile */
    flex-direction: column; /* Stack elements vertically */
  }

  .text {
    text-align: center; /* Ensure text is centered */
    margin-bottom: 5px; /* Smaller margin */
    
  }

  .button {
    width: 100%; /* Full-width button for easier tapping */
    margin-top: 10px;
    margin-left: 0;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1em; /* Ensure readable font size */
  }
}
