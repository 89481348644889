.testimonialContainer {
    padding: 20px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.userImage {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 15px;
}

.userName {
    margin-bottom: 10px;
    font-size: 20px;
}

.userTestimony {
    font-size: 16px;
    text-align: center;
    line-height: 1.6;
}
