
.container {
    position: relative;
    padding: 20px;
    background-color: #B6B6B6;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 20px;
        margin-top: 10px;
        font-size: 16px; 
    }
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.mushroomSection {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
        margin-bottom: 10px; 
    }
}

.statBox {
    position: absolute;
    padding: 5px;
    border: 1px solid #ccc;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 4px;
    z-index: 1;
}

.topStat {
    top: -10px; 
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 255, 255, 0.7); 

    @media (max-width: 768px) {
        font-size: 14px;
        padding: 10px; 
    }
}

.leftStat {
    left: 10px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 768px) {
       margin-top: 15px;
    }
}

.rightStat {
    right: 10px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: 768px) {
       margin-top: 15px;
    }
}

.accessoriesSection {
    display: flex;
    flex-direction: column; 
    align-items: center;     
    gap: 10px;
    padding: 10px; 
    background-color: #f4f4f4; 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.accessoryBox {
    border: 1px solid #ccc;
    padding: 5px;
    margin: 5px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.9);
    width: 150px;  
    text-align: center; 
}

.itemTitle {
    font-weight: bold;
}

.tooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    position: absolute;
    z-index: 2;
    bottom: 125%; 
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}


.tooltipContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
}

.tooltipContainer:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.skillsSection {
    display: flex;
    flex-direction: column; 
    align-items: center;     
    gap: 10px;
    padding: 10px; 
    background-color: #f4f4f4; 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.skill {
    position: relative;
    border: 1px solid #ccc;
    display: inline-block;
    padding: 5px;
    margin: 5px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.9);
}

.updated {
  margin-top: 20px;
  font-style: italic;
  font-size: 14px;
  color: #555;
}

/*const AccessoryImage = styled.img`
    position: absolute;
    max-width: 43%; 
    max-height: 43%; 
    z-index: 1;     
`;*/

.section {
  margin: 10px 0;
}

.sectionHeader {
    background-color: #00796B;
    color: white;
    padding: 10px;
    cursor: pointer;
    width: 300px;
    border-radius: 10px 10px 0 0;
}

@media (max-width: 768px) {
    .sectionHeader {
       width: 200px;
    }
    .sectionHeader.open {
       border-radius: 10px 10px 0 0;
    }
    .sectionHeader.closed {
       border-radius: 10px;
    }
}


.sectionContent {
    display: block;
}

@media (max-width: 768px) {
    .sectionContent {
       display: none;
    }
    .sectionContent.open {
       display: block;
    }
}

.imageWrapper {
    margin-top: 40px;
    position: relative;
    z-index: 0;
    overflow: hidden;
    transform: scale(0.85);
}

@media (max-width: 768px) {
    .imageWrapper {
        transform: scale(0.60);
        margin-top: 30px;
    }
}



.smallImageWrapper {
    display: inline;
    margin-top: 0px;
}

