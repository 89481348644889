.container {
    @media (max-width: 768px) {
        padding: 50px 20px;
    }
}

.journeyContainer {
    max-width: 800px;
    margin: 50px auto;
    text-align: center;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        margin: 30px auto;
    }
}

.title {
    font-size: 2.5rem;
    color: #2A9D8F;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 2rem;
        margin-bottom: 10px;
        margin-top: 40px;
    }
}

.description {
    font-size: 1.2rem;
    margin-bottom: 20px;
}

.highlight {
    font-weight: bold;
    color: #1E7767;
}

.signupButton {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 22px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.signupButton:hover {
    background-color: #1E7767;
}

.signupButton:active {
    transform: scale(0.95);
}
