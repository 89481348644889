body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Lato', 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 32px;
    
  @media (min-width: 768px) {
    font-size: 28px;
  }
}

h2 {
    font-size: 28px;

    @media (min-width: 768px) {
      font-size: 18px;
    }
}


/* These are used by the blogs only as they are stored in the database so I wanted stable class names, not dynamically created. */

/* General Styling */
.blog-textAlignLeft {
  text-align: left;
  margin: 20px 0;
}

.blog-text {
  font-size: 16px;
  color: #264653; 
  margin-right: 5px;
}

.blog-styledLink {
  text-decoration: none;
  color: #E76F51; 
  font-weight: 800;
  transition: color 0.3s ease, transform 0.3s ease;
}

.blog-styledLink:hover {
  color: #D9534F; 
  transform: scale(1.05);
}

/* Image Styling */
.blog-imageSmall, .blog-imageMedium, .blog-imageLarge {
  margin-bottom: 20px;
  border-radius: 8px;
}

.blog-imageSmall {
  width: 10%;
  height: 10%;
}

.blog-imageMedium {
  width: 40%;
  height: 40%;
}

.blog-imageLarge {
  width: 60%;
  height: 60%;
}

.blog-linkImageSmall {
  width: 10%;
  height: 10%;
}

.blog-linkImageMedium {
  width: 40%;
  height: 40%;
}

.blog-linkImageLarge {
  width: 60%;
  height: 60%;
}

.blog-linkImageMedium:hover, .blog-linkImageLarge:hover {
  transform: scale(1.05);
}

.blog-linkImageMedium:hover, .blog-linkImageLarge:hover {
  transform: scale(1.05);
}

/* Container for the image */
.blog-imageContainer {
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.blog-imageContainer img {
  border-radius: 10px;
  #transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease; 
}

.blog-imageContainer img:hover {
  #transform: scale(1.02); 
  border: 3px solid #2A9D8F; 
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
}

.blog-productLink {
  display: block;
  text-align: center;
  margin-bottom: 10px;
}

.blog-productDescription {
  margin: 0;
  font-size: 14px;
  color: #333;
  text-align: center;
}

/* Image Alignment */
.blog-alignLeft {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
}

.blog-alignRight {
  float: right;
  margin-left: 10px;
  margin-bottom: 10px;
}

.blog-alignCenter {
  display: block;
  margin: 0 auto 10px auto;
}

/* Image Titles */
.blog-imageTitleCenter, .blog-imageTitleLeft, .blog-imageTitleRight {
  font-size: 14px;
  font-style: italic;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.05em;
  margin: 10px 0; 
  display: inline-block;
  background-color: #E0E0E0;
  padding: 3px 6px; 
  border-bottom: 2px solid #ccc; 
}

@media (max-width: 768px) {
  .blog-imageTitleCenter, .blog-imageTitleLeft, .blog-imageTitleRight {
    font-size: 12px;
  }
}

/* Call to Action (CTA) */
.blog-ctaContainer {
  background-color: #F4A261; 
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  margin: 20px 0;
}

.blog-ctaText {
  color: #264653; 
  font-size: 16px;
  margin-bottom: 10px;
}

.blog-ctaButton {
  text-decoration: none;
  color: #FFF; 
  background-color: #2A9D8F;
  padding: 12px 24px;
  font-weight: 800;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.blog-ctaButton:hover {
  background-color: #21867A; 
  transform: scale(1.05);
}

/* Style for in-text citation */
cite {
    font-style: normal;
    color: #007acc;
    cursor: pointer;
}

/* Style for the references section */
.blog-references {
    list-style: none;
    padding-left: 0;
}

.blog-references li {
    margin-bottom: 10px;
}

.blog-references a {
    text-decoration: none;
    color: #007acc;
}

.blog-references a:hover {
    text-decoration: underline;
}




/* These are used to make the screens with less content, take up the full height
so that the footer is at the bottom. 

How to use: <main className={`container ${styles.container}`}> 
That will pick up the global container style plus the local. */
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
}
