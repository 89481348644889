.nav {
    padding: 20px;
    background-color: #00796B;
    display: flex;
    justify-content: space-between;
    font-family: Lato, sans-serif;
}

@media (max-width: 768px) {
    .nav {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
}

.navItem {
    color: #FAFAFA;
    font-size: 1rem;
    flex: 1;
    text-transform: uppercase;
    text-decoration: none;
    margin-right: 20px;
    padding: 5px 10px;
    border-radius: 5px;
}

.navItem:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
    .navItem {
        font-size: 0.8rem;
        margin-right: 0;
        margin-bottom: 10px;
    }
}

.navItemBrand {
    font-size: 2rem;
    flex: 1;
}

.navItemBrand:hover {
    background-color: transparent;
}

@media (max-width: 768px) {
    .navItemBrand {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }
}

.separator {
    display: none;
    color: #FAFAFA;
    padding: 0 5px;
}

@media (max-width: 768px) {
    .separator {
        display: none;
    }
}

.signupButton {
    font-family: Lato, sans-serif;
    padding: 5px 15px;
    background-color: #029A82;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    margin-top: 3px;
    margin-left: 5px;
    margin-bottom: 10px;
    
    @media (max-width: 768px) {
        padding: 3px 10px;
        margin-left: 15px;
        font-size: 0.8rem;
    }
}

.signupButton:hover {
    background-color: #CE6F72;
}

.loginButton {
    padding: 5px 15px;
    background-color: #029A82;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    
}

.buttonContainer {
    margin-top: 5px;
    margin-left: 3px;
}

.welcomeMessageStyle {
    color: #FAFAFA;
    margin-right: 10px;
}

.profileBlock {
    flex: 1;
    border: 1px solid #444;
    margin-right: 5px;

    @media (max-width: 768px) {
        border: none;
    }
}

.basketIcon {
    position: relative;
    cursor: pointer;
}

.basketIconCount {
    position: absolute;
    top: -8px;
    right: 2px;
    color: white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    font-weight: 600;
}

.styledBasketImage {
    width: 30px;
}

.hiddenBasket {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}

.mushroomIcon {
    position: relative;
    cursor: pointer;
    margin-left: 3px;
}

.styledMushroomImage {
    width: 30px;
    height: 30px;
}

.hiddenMushroom {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}
