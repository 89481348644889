.feedbackContainer {
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #fff0e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.feedbackText {
    font-family: Lato, sans-serif;
    font-size: 1em;
    margin-bottom: 10px;
    margin-top: 0px;
}

.buttonGroup {
    display: flex;
    gap: 10px;
}

.feedbackButton {
    padding: 5px 15px;
    font-size: 14px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: Lato, sans-serif;
}

.feedbackButton:hover {
    background-color: #1E7767;
}

.feedbackLink {
    font-size: 14px;
    margin-top: 10px;
    text-decoration: none;
    color: #2A9D8F;
}

.feedbackLink:hover {
    text-decoration: underline;
}
