.container {
    width: 100%;
    background-color: #e6f7ff;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
}

.list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
}

.listItem {
    display: flex;
    flex-wrap: wrap;
    /* Allow items to wrap on smaller screens */
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ccc;
}

.listItem:nth-child(odd) {
    background-color: #f2f2f2;
}

.noGoals {
    text-align: center;
    padding: 8px;
    margin-bottom: 10px;
}

.title {
    font-weight: 600;
    font-size: 1.2em;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.actionButton {
    padding: 5px 10px;
    margin: 5px 0 5px 5px;
    cursor: pointer;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
    white-space: nowrap;
}

.actionButton:hover {
    background-color: #1E7767;
}

.actionButton:active {
    transform: scale(0.95);
}

.styledInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;
    margin-right: 10px;
    flex: 5;

}

.addGoalArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    width: 100%;
}

.goalDescription {
    flex: 7;
    font-size: 0.8em;
    margin-left: 60px;
    font-weight: 800;
}

.goalDate {
    flex: 1;
    font-size: 0.8em;
    margin: 2px 0;
}

.targetDate {
    flex: 1;
    font-size: 0.8em;
    margin: 2px 0;
}

.headings {
    display: flex;
}

.addGoalHeadings {
    display: flex;
    border-top: 2px solid #ccc;
}

.checkbox {
    flex: 1;
    margin: 0px;
}

.achievedLabel {
    flex: 1;
}

.descriptionLabel {
    flex: 6;
}

.dateLabel {
    flex: 1;
}

.targetDateLabel {
    flex: 1;
}

.addDateLabel {
    flex: 1;
    font-size: 0.7em;
    margin-top: 10px;
}

.addDescriptionLabel {
    flex: 5;
    font-size: 0.7em;
    margin-top: 10px;
}

.styledDateInput {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    margin-right: 10px;
    cursor: pointer;
    font-family: Lato, sans-serif;
    width: calc(100% - 22px); 
    flex: 1;
}

.styledDateInput:focus {
    border-color: #2A9D8F;
    outline: none;
    box-shadow: 0 0 0 2px rgba(42, 157, 143, 0.25);
}

.styledDateInput:disabled,
.styledDateInput[readonly] {
    background-color: #e6e6e6;
    cursor: not-allowed;
}

.addMobileDateLabel {
    display: none;
}

@media (max-width: 768px) {
    .listItem {
        justify-content: flex-start;
    }

    .goalDescription,
    .goalDate {
        flex-basis: 100%;
        margin: 5px 0;
    }

    .actionButton {
        padding: 5px;
    }

    .addGoalArea {
        flex-direction: column;
    }

    .addGoalArea .styledInput,
    .addGoalArea .actionButton {
        width: 100%;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .dateLabel {
        display: none;
    }

    .descriptionLabel {
        display: none;
    }

    .achievedLabel {
        display: none;
    }

    .addDateLabel {
        display: none;
    }
    
    .addDescriptionLabel {
        display: none;
    }

    .targetDateLabel {
        display: none;
    }

    .goalDate {
        display: none;
    }

    .addMobileDateLabel {
        display: block;
        margin-bottom: 5px;  
        font-size: 0.9em;
    }
}

