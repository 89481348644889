.profileMenu {
  position: relative;
  display: inline-block;
  padding-right: 30px;
  font-family: Lato, sans-serif;

  @media (max-width: 769px) {
    margin-bottom: 10px;
  }
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  border: 1px solid #ccc;
  z-index: 8;
  border-radius: 4px;
  font-size: 0.7em;
}

.dropdownContent a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdownContent a:hover {
  background-color: #f1f1f1;
}

.show {
  display: block;
}

.profileButton {
  padding: 5px 5px;
  background-color: #029A82;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.9em;
}

.profileButton:hover {
  background-color: #CE6F72;
}

.styledButton {
  text-align: left;
  padding: 12px 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: inherit;
  color: inherit;
  width: 100%;
  background-color: #f1f1f1;
  margin-bottom: 3px;
  border-radius: 6px;
}

.styledButton:hover {
  background-color: #C5B3B3;
}

.welcomeMessage {
  font-weight: 600;
  text-align: center;
  border-bottom: 0.5px solid black;
  margin: 5px;
  padding-bottom: 5px;
}

.mushroom {
  font-weight: 400;
  text-align: left;
  margin: 5px;
  margin-top: 20px;
}

.mushroomIcons {
  font-weight: 400;
  text-align: left;
  margin: 5px;
}

.mushroomCount {
  font-size: 0.8em;
  margin-right: 10px;
}
