.container {
    margin-top: 10px;
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center; 
    background-color: #e6ffe6;
    border: 2px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    @media (min-width: 769px) {
        #align-items: flex-start;
    }
}

.styledPhaserGameWrapper {
    border: 1px solid #e0e0e0;
    padding: 5px;
    border-radius: 5px;
    width: 362px;
    height: 510px;
    position: relative;
}

.title {
    margin-bottom: 0px;
    margin-left: 10px;
    font-size: 24px;
    text-align: center;
}

.buttonContainer {
    display: flex;
    gap: 10px;
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.gameControls {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0px;

    @media (max-width: 768px) {
        gap: 10px;
    }
}

.controlButton {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #2A9D8F;
    color: white;
    cursor: pointer;

    @media (max-width: 768px) {
        padding: 12px 20px;
        width: 100%;
    }
}

.controlButton:hover {
    background-color: #1E7767;
}


.controlButton:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
}

.gameStateInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
}

.healthBar {
    height: 20px;
}

.green {
    background-color: green;
}

.red {
    background-color: red;
}

.mushroomImagePlaceholder {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    margin-left: 10px;
}

.soundToggle {
    cursor: pointer;
    margin-right: 10px;
}

.titleBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
