
.container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px; 
        margin-top: 0px; 
    }
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.paragraph {
    margin-bottom: 15px;
    line-height: 1.5;
}

.donateButton {
    display: block;
    margin: 20px auto;
}

.bitcoinQR {
    max-width: 300px;
    margin: 0 auto;
    display: block;
}

.bitcoinContainer {
    text-align: center;
    margin-bottom: 20px;
}
