.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Lato, sans-serif;
}

.title {
  font-weight: 600;
  font-size: 32px;
  color: #2A9D8F;
  margin-bottom: 20px;
  text-align: center;
}

.dashboardSection {
  width: 100%;
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
}
