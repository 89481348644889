.sarahIntroContainer {
    padding: 20px;
    font-family: Lato, sans-serif;
    background-color: #ffe6f9;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    font-family: Lato, sans-serif;
    
    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.title {
    color: #ff66cc;
    font-weight: bold;
    text-align: center;
}

.introText {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    margin-bottom: 20px;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

.sarahImage {
    max-width: 200px;
    max-height: 200px;
    border-radius: 50%;
}
