.container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.productImage {
    width: 20%;
    height: 20%;
    margin-bottom: 20px;
}

.styledButton {
    margin: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.styledButton:hover {
    background-color: #1E7767;
}

.optionButton {
    margin: 5px;
    padding: 10px;
    background-color: #ECECEC;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.optionButton:hover {
    background-color: #DDDDDD;
}

.optionButton.selected {
    background-color: #2A9D8F;
    color: white;
}

.colourSwatch {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin: 5px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #000;
    transition: border 0.3s, box-shadow 0.3s;
}

.colourSwatch.selected {
    border-color: #ffffff;
    box-shadow: 0 0 12px rgba(42, 157, 143, 0.6);
}

.colourSwatch:hover {
    box-shadow: 0 0 12px rgba(42, 157, 143, 0.6);
}

.mandatoryNote {
    bottom: 10px;
    right: 10px;
    font-size: 0.8rem;
    color: red;
    text-align: right;
}

.styledInput {
    flex-grow: 1;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 25%;
    margin-left: 5px;

    @media (min-width: 769px) {
        width: auto;
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    margin: 5px;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.1s;
}

.quantityContainer {
    display: flex;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 10px;
}

.quantityStepper {
    display: flex;
    border: 1px solid #ccc;
    width: fit-content;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-left: 10px;
    margin-top: 20px;
}

.quantityStepper button {
    padding: 5px 15px;
    cursor: pointer;
    border: none;
    background-color: #ECECEC;
    transition: background-color 0.3s, box-shadow 0.3s;
    border-radius: 5px;
}

.quantityStepper button:hover {
    background-color: #DDDDDD;
}

.quantityStepper button:active {
    background-color: #BFBFBF;
    box-shadow: inset 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.quantityStepper button:focus {
    outline: none;
    box-shadow: 0px 0px 5px rgba(42, 157, 143, 0.6);
}

.quantityStepper input {
    width: 50px;
    text-align: center;
    border: none;
    font-size: 0.9em;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
}

/* removing spinner arrows for Firefox */
.quantityStepper input::-webkit-outer-spin-button,
.quantityStepper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.description {
    font-size: 0.8em;
}

.price {
    font-size: 0.8em;
}

.label {
    font-size: 0.9em;
    font-weight: 800;
}

.colourLabel {
    vertical-align: 50%;
    font-size: 0.9em;
    font-weight: 800;
}
