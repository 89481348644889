.container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px; 
        margin-top: 0px; 
    }
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.tableRow {
    @media (max-width: 768px) {
        display: none;
    }
}

.tableRow:nth-child(even) {
        background-color: #f2f2f2;
    }

.tableHeader {
    border-bottom: 2px solid #ddd;
    padding: 12px 8px;
    text-align: left;
}

.tableCell {
    padding: 8px;
}

.paragraph {
    margin-bottom: 15px;
    line-height: 1.5;
}

.card {
    display: none;

    @media (max-width: 768px) {
        display: block;
        margin-bottom: 15px;
        border: 1px solid #e0e0e0;
        padding: 10px;
        border-radius: 5px;
    }
}

.cardItem {
    margin-bottom: 5px;
}
