.questionsContainer {
    padding: 20px;
    font-family: Lato, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    font-family: Lato, sans-serif;
}

.fetchButton {
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px;
}

.fetchButton:hover {
    background-color: #1E7767;
}

.fetchButton:active {
    transform: scale(0.95);
}

.fetchButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}
