.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Lato, sans-serif;
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.form {
    width: 80%;
}

.formField {
    margin-bottom: 20px;
}

.label {
    display: block;
    margin-bottom: 10px;
}

.input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    height: 1500px;
}

.select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
}

.button {
    padding: 10px 20px;
    margin-right: 5px;
    font-size: 16px;
    cursor: pointer;
}

.previewArea {
    width: 100%;
    height: 250px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    overflow-y: auto;  
    white-space: pre-wrap;  
    word-wrap: break-word;  
}

.buttonGroup {
    display: flex;
    align-items: center;
    gap: 20px;  
}

.checkboxLabel {
    font-size: 0.9rem;
}

.dropdown {
    display: block;
    position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 100; /* to ensure it overlays other elements */
}

.dropdown button {
    display: block;
    width: 100%;
    padding: 5px 10px;
    background-color: transparent;
    border: none;
    text-align: left;
}

.secondBtnRow {
    margin-top: 5px;
}
