.commentForm {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.commentTextarea {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 100px;
    resize: vertical;
}

.commentButton {
    padding: 10px 20px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
    font-size: 1em;
  }
  
  .commentButton:hover {
    background-color: #1E7767;
  }
  
  .commentButton:active {
    transform: scale(0.95);
  }
