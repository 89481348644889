.feedbackContainer {
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #e6f7ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    text-align: center;
    font-family: Lato, sans-serif;
}

.feedbackText {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 0px;
}

.star {
    font-size: 24px;
    margin: 0 5px;
    cursor: pointer;
}

.star.active {
    color: gold;
}

.submitButton {
    font-family: Lato, sans-serif;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.submitButton:hover {
    background-color: #1E7767;
}
