*, *:before, *:after {
    box-sizing: border-box;
}

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: Lato, sans-serif;
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.emptyBasketMessage {
    color: #757575;
    font-style: italic;
    margin: 20px 0;
}

.checkoutButton,
.removeButton {
    padding: 10px 20px;
    margin: 5px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.checkoutButton {
    width: auto;
    margin: 1rem auto; 
    font-size: 1.1em;
}

.checkoutButton:hover,
.removeButton:hover {
    background-color: #1E7767;
}

.basketSummary {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
}

.totalItems {
    font-weight: bold;
}

.totalPrice {
    color: #00796B;
    font-weight: bold;
    font-size: 1.2em;
}

.paypalButtonContainer {
    width: auto;
    margin: 1rem auto;
}

.paymentNote {
    margin-top: 20px;
    color: #757575;
    font-style: italic;
    font-size: 0.9em;
    text-align: center;
}

.basketHeader,
.basketItem {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
}

/*.basketItem {
    flex-direction: row;
}*/

.label, 
.itemDetail {
    flex: 1;
    text-align: center;
    padding: 0.5em;
    margin: 0;
}

.itemDetail {
    font-size: 0.9em;
}

.headerName, 
.headerBasketKey, 
.headerUnitPrice, 
.headerSize, 
.headerColour, 
.headerQuantity, 
.headerTotalPrice,
.itemName,
.itemBasketKey,
.itemUnitPrice,
.itemSize,
.itemColour,
.itemQuantity,
.itemTotalPrice {
    flex: 1;
    text-align: center;
    padding: 0.5em;
}

.removeButton {
    flex-basis: auto;
    width: 100px;
    margin: 0 auto;
}

.headerRemove {
    flex-basis: 100px;
    padding: 0.5em;
    text-align: center;
}



.inlineLabel {
    display: none; /* Hide inline labels on desktop */
}


@media (max-width: 767px) { /* Adjust breakpoint as needed */

    .basketHeader {
        display: none; /* Hide the header on mobile */
    }

    .inlineLabel {
        display: inline-block; /* Show inline labels on mobile */
        
    }

    /* Make text slightly larger for better readability */
    .itemName, .itemPrice {
        font-size: 18px;
    }

    /* Increase button sizes for better tap targets */
    .checkoutButton, .removeButton {
        padding: 12px 24px;
        font-size: 20px;
        width: 100%;
        margin: 10px 0;
    }

    /* Stack basketHeader and basketItem vertically */
    .basketItem {
        flex-direction: column;
    }

    .basketItem > div {
        /* Full width, smaller padding, and centered text */
        width: 100%;
        padding: 0.25em;
        text-align: center;
    }

    /* Adjust total summary for mobile */
    .basketSummary {
        flex-direction: column;
        align-items: center;
    }

    .totalItems, .totalPrice {
        margin: 5px 0;
    }

    /* Adjusting other elements for better spacing */
    .paymentNote, .paypalButtonContainer {
        margin-top: 15px;
    }

    .basketItem {
        flex-direction: column;
        border: 1px solid #eee;
        margin: 10px 0;
        padding: 10px;

    }

    .basketItem > div {
        margin-bottom: 5px;
    }

    .basketItem > div strong {
        margin-right: 5px;
    }

    .removeButton {
        flex-basis: auto;
        width: 50%;
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .itemName,
    .itemBasketKey,
    .itemUnitPrice,
    .itemSize,
    .itemColour,
    .itemQuantity,
    .itemTotalPrice {
        flex-direction: row;
        align-items: left;
    }
}

