.booksContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    padding: 40px 10px;
    font-family: 'Lato', sans-serif;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}

.title {
    font-weight: 600;
    font-size: 24px;
    color: #2A9D8F;
    text-align: center;
}

.booksGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Automatically fits 2-4 books per row */
    gap: 20px;
    justify-items: center;
    width: 100%;
    max-width: 900px;
}

.bookCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #f5f5f5;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    width: 100%;
    max-width: 250px; /* Keeps book cards at a reasonable size */
}

.bookCard:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.bookImage {
    width: 150px; /* Ensures consistent width */
    height: 200px; /* Forces all images to be the same height */
    object-fit: cover; /* Ensures images crop instead of stretching */
    border-radius: 5px;
    margin-bottom: 10px;
}


/* Responsive layout for smaller screens */
@media (max-width: 768px) {
    .booksGrid {
        grid-template-columns: repeat(2, 1fr); /* 2 books per row on tablets */
    }
}

@media (max-width: 480px) {
    .booksGrid {
        grid-template-columns: 1fr; /* Stacks to 1 per row on mobile */
    }

    .bookImage {
        width: 100px; /* Smaller images for mobile */
    }
}

.bookTitle {
    font-weight: bold;
    font-size: 18px;
    color: #2A9D8F;
    margin-bottom: 10px;
}

.bookDescription {
    text-align: left;
    font-size: 14px;
    color: #333;
    margin-bottom: 15px;
    padding: 0 10px;
}

.bookLink {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: Lato, sans-serif;
    text-decoration: none;
    font-weight: bold;
    text-align: center;
}

.bookLink:hover {
    background-color: #1E7767;
}

.bookLink:active {
    transform: scale(0.95);
}
