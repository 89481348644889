.container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 40px auto;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.form {
    display: flex;
    flex-direction: column;
}

.input {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 16px;
}

.textarea {
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    font-size: 16px;
    resize: vertical;
    min-height: 100px;
    margin-bottom: 10px;
}

.submitButton {
    padding: 10px 20px;
    background-color: #2A9D8F;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.1em;
}

.submitButton:hover {
    background-color: #1E7767;
}

.submitButton:active {
    transform: scale(0.95);
}

.feedbackText {
    font-size: 16px;
    color: #333;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: center;  
}

.mushroomIconWrapper {
    position: relative;
    width: 40px; 
    height: 40px; 
    display: inline-block;
    vertical-align: middle; 
}

.userFeedback {
    margin-top: 30px;
}
