.questionsContainer {
    padding: 20px;
    font-family: Lato, sans-serif;
}

.questionRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 8px;
        align-items: stretch; 
    }
}

.questionLabel {
    display: flex;
    align-items: center;
    flex: 1;
    min-width: 0;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: center;
        flex-direction: column; 
    }
}

.questionText {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    min-width: 0;

    @media (max-width: 768px) {
        white-space: normal;
        text-align: center;
        width: 100%; 
    }
}

.questionAnswer {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 200px;
    flex-shrink: 0;

    @media (max-width: 768px) {
        width: 100%; 
    }
}

.generateReportButton {
    font-size: 18px;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: blue;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.generateReportButton:disabled {
    background-color: #b0b0b0;
    cursor: not-allowed;
}

.generateReportButton:hover {
    background-color: #0056b3;
}

.serverResponse {
    margin-top: 20px;
}

.infoMessage {
    font-size: 16px;
    color: #888;
    margin-top: 10px;
    text-align: center;
}

.startAgainButton {
    font-size: 18px;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #C9E4DE;
    color: #2A5A59;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.startAgainButton:hover {
    background-color: #B0D0CC;
}

.buttonGroup {
    display: flex;
    justify-content: center;
    gap: 20px; 
}

.serverErrorMessage {
    color: red;
    border: 1px solid red;
    padding: 15px;
    border-radius: 5px;
    margin-top: 10px;
}

.paginationContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    background-color: #fff0e6;
}

.pageButton {
    padding: 10px 20px;
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
}

.pageButton:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
}

.progressBarContainer {
    width: 100%;
    background-color: #fff0e6;
    border-radius: 5px;
    margin: 15px 0;
    height: 20px;
    position: relative;
}

.progressBarFiller {
    background-color: blue;
    height: 100%;
    border-radius: inherit;
    transition: width 0.3s ease-in-out;
}

.progressText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.9rem;
    color: black;
}

.pageIndicatorContainer {
    padding: 10px 20px;
    background-color: #fff0e6;
    border-radius: 5px;
    font-weight: bold;
    font-size: 0.9rem;
    display: inline-block;
    margin: 10px 0;
}

.spinnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    flex-direction: column;
}

.randomMessage {
    margin-top: 25px;
}
