.footer {
    padding: 20px 20px;
    background-color: #333;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    border-top: 1px solid #444;
    font-family: Lato, sans-serif;
}

@media (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.linkGroup {
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    padding: 10px 0;
}

@media (max-width: 768px) {
    .linkGroup {
        margin: 0;
    }
}

.linkGroup:first-child {
    margin-left: 0;
}

.linkGroup:last-child {
    margin-right: 0;
}

.styledLink {
    margin-bottom: 10px;
    font-size: 0.8em;
    color: #ddd;
    text-decoration: none;
    transition: color 0.3s;
}

.styledLink:hover {
    color: #fff;
}

.socialMediaGroup {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.socialMediaLink {
    margin: 0 15px;
    transition: transform 0.3s;
}

.socialMediaLink:first-child {
    margin-left: 0;
}

.socialMediaLink:last-child {
    margin-right: 0;
}

.socialMediaLink:hover {
    transform: scale(1.1);
}

.copyrightText {
    margin-top: 30px;
    flex-basis: 100%;
    text-align: center;
    font-size: 0.8em;
    color: #aaa;
}
