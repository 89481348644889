
.container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    border: 1px solid #eee;
    margin: 20px auto;
    font-family: Lato, sans-serif;
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.toolAction {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.actionButton {
    padding: 8px 15px;
    margin-top: 10px;
    cursor: pointer;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.note {
    font-size: 12px;
    color: #555;
}

.result {
    padding: 10px;
    border: 1px solid #aaa;
    width: 100%;
    overflow: auto;
    margin-top: 20px;
}

