.blogContainer {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 40px auto;
  font-family: Lato, sans-serif;
}

.blogTitle {
  text-align: center;
  margin-bottom: 20px;
  font-size: 36px;
}



.blogContent {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
}

.blogFooter {
  margin-top: 20px;
  font-style: italic;
  font-size: 14px;
  color: #555;
}

@media only screen and (max-width: 768px) {
  .blogTitle {
    font-size: 28px;
  }

  .blogContainer {
    padding: 15px;
    max-width: 95%;
  }

  .blogContent,
  .blogFooter {
    font-size: 16px;
  }
}

@media only screen and (max-width: 480px) {
  .blogTitle {
    font-size: 28px;
  }

  .blogContainer {
    padding: 10px;
  }

  .blogContent,
  .blogFooter {
    font-size: 16px;
  }
}

.commentSection {
  margin-top: 40px;
}

.videoTitle {
text-align: center;
    font-size: 14px;
    font-style: italic;
    color: darkgray;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    letter-spacing: 0.05em;
    margin: 10px 0; 
    display: inline-block;
    background-color: #f8f8f8; 
    padding: 3px 6px; 
    border-bottom: 2px solid #ccc; 
    

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.loginPrompt {
  color: #666;
  font-size: 1rem;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 5px;
  text-align: center;
  margin-top: 20px;
}

.styledLink {
  text-decoration: none;
  color: #2A9D8F;
  font-weight: 800;
  transition: all 0.3s ease;
}

.styledLink:hover {
  text-decoration: underline;
}
