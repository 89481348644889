.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: Lato, sans-serif;
}

.styledText {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;

    @media (min-width: 769px) {
        width: auto;
    }
}

.editButton {
    padding: 10px 20px;
    background-color: #2A9D8F;
    font-size: 22px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.editButton:hover {
    background-color: #1E7767;
}


.styledLabel {
    font-weight: bold;
    width: 190px;
    text-align: right;
    margin-right: 10px;

    @media (max-width: 768px) {
        text-align: left;
    }
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.profileGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}
