.container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
    font-family: Lato, sans-serif;

    @media (max-width: 768px) {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.paragraph {
    margin-bottom: 15px;
    line-height: 1.5;
}

.paragraph strong {
    font-weight: bold;
}

.paragraph ul {
    margin-left: 20px;
    list-style-type: disc;
}

.paragraph li {
    margin-bottom: 10px;
}

.input {
    width: 70%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-right: 10px;
}

.button {
    padding: 10px 15px;
    background-color: #2A9D8F;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s;
}

.button:hover {
    background-color: #1E7767;
}

.button:active {
    transform: scale(0.95);
}
