.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    font-family: Lato, sans-serif;
}

.styledInput {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;

    @media (min-width: 769px) {
        width: auto;
    }
}

.updateButton {
    padding: 10px 20px;
    background-color: #2A9D8F;
    font-size: 22px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
}

.updateButton:hover {
    background-color: #1E7767;
}


.styledLabel {
    font-weight: bold;
    width: 190px;
    text-align: right;
    margin-right: 10px;

    @media (max-width: 768px) {
        text-align: left;
    }
}

.mandatoryMarker {
    color: red;
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.inputGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.styledSelect {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    appearance: none;
    font-size: 14px;
    width: 100%;

    @media (min-width: 769px) {
        width: auto;
    }
}

.styledSelect:focus {
    border-color: #007bff;
}

.mandatoryNote {
    font-size: 0.9rem;
    text-decoration: none;
    color: #666;
    margin-top: 10px;
}

.validationBlock {
    color: red;
    font-size: 0.8em;
    margin-left: 5px;
}

.cancelButton {
    font-size: 18px;
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #C9E4DE;
    color: #2A5A59;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancelButton:hover {
    background-color: #B0D0CC;
}
