.container {
    padding: 20px;
    background-color: #f4f4f4;
    font-family: Lato, sans-serif;
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}

@media (max-width: 768px) {
    .container {
        padding: 15px;
        margin-bottom: 10px;
        margin-top: 0px;
    }
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.title strong {
    font-weight: 800;
}

.description {
    font-size: 18px;
    margin-bottom: 20px;
    line-height: 1.5;
}

.description strong {
    font-weight: 800;
}

.bullet {
    font-weight: bold;
    margin-left: 20px;
    text-align: left;
}

.feedback {
    width: 35%;
    margin: 0 auto;
}

.section {
    margin-bottom: 50px;
}
