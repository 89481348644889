.container {
    overflow-x: auto;
    margin: 10px;
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
    font-size: 0.7em;
}

.table th {
    background-color: #f4f4f4;
}

@media screen and (max-width: 768px) {
    .table {
        display: block;
    }
}

.uuid {
    font-size: 0.8em;
}
