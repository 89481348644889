.container {
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.siteMapList {
    list-style-type: none;
    padding-left: 0;
}

.siteMapItem {
    margin-bottom: 10px;
}

.siteMapLink {
    text-decoration: none;
    color: blue;
}

.siteMapLink:hover {
    text-decoration: underline;
}

.siteMapItemDescription {
    margin-bottom: 10px;
}

