.container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 800px;
    margin: 40px auto;
    font-family: Lato, sans-serif;
}

.title {
    text-align: center;
    margin-bottom: 20px;
}

.summary {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 30px;
}

.styledLink {
    text-decoration: none;
    color: #2A9D8F;
    font-weight: 600;
}

.styledLink:hover {
    text-decoration: underline;
}
