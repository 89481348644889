.container {
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
    background-color: #fff0e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
    margin-bottom: 20px;
    font-family: Lato, sans-serif;
    width: max-content;
  }
  
  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .description {
    font-size: 14px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .board {
    display: grid;
    grid-template-columns: repeat(9, 40px);
    grid-gap: 5px;
  }
  
  .row {
    display: contents;
  }
  
  .cell {
    width: 40px;
    height: 40px;
    font-size: 18px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0;
  }
  
  .cell:nth-child(3n) {
    border-right: 2px solid #888;
  }
  
  .cell:nth-child(3n + 1) {
    border-left: 2px solid #888;
  }
  
  .cell:nth-child(-n + 27), .cell:nth-child(n + 27):nth-child(-n + 54) {
    border-bottom: 2px solid #888;
  }
  
  .cell:nth-child(n + 54):nth-child(-n + 81) {
    border-top: 2px solid #888;
  }
  