.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Lato, sans-serif;
}

.title {
    font-weight: 600;
    font-size: 32px;
    color: #2A9D8F;
    margin-bottom: 20px;
    text-align: center;
}

.subTitle {
    font-weight: 600;
    font-size: 24px;
    color: #2A9D8F;
}

.form {
    width: 80%;
}

.formGroup {
    margin-bottom: 15px;
}

.label {
    display: block;
    margin-bottom: 5px;
}

.input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.submitButton {
    padding: 10px 20px;
    background-color: #2A9D8F;
    font-size: 18px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 20px;
}

.submitButton:hover {
    background-color: #1E7767;
}

.paypalButtonContainer {
    width: auto;
    margin: 1rem auto;
}

.paymentNote {
    margin-top: 20px;
    color: #757575;
    font-style: italic;
    font-size: 0.9em;
    text-align: center;
}

.styledInput {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    width: 100%;

    @media (min-width: 769px) {
        width: auto;
    }
}

.styledLabel {
    font-weight: bold;
    width: 190px;
    text-align: right;
    margin-right: 10px;

    @media (max-width: 768px) {
        text-align: left;
    }
}

.mandatoryMarker {
    color: red;
}

.inputGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 5px 0;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
    }

}

.validationBlock {
    color: red;
    font-size: 0.8em;
    margin-left: 5px;
}

.mandatoryNote {
    font-size: 0.9rem;
    text-decoration: none;
    color: #666;
    margin-top: 10px;
}
