.content {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    background-color: #323232;
    color: white;
    border-radius: 4px;
}

.message {
    flex: 1;
}

.styledButton {
    margin-left: 8px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}
